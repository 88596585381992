import { createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "firebase-config";
import { FetchOptions, postURI } from "./baseFunction/ApiService";

/**
 * 서버로부터 검색결과 받아오기
 */
interface searchProps {
    pageNum: number,
    pagingSize: number,
    searchText: string,
};

// 풀었던 문제 받아오기
export const getQuizPlayed = createAsyncThunk( 
    '/quiz/user/played',
    async () => {
        // firebase에서 userToken받아오기
        let userToken = await auth.currentUser?.getIdToken();

        const fetchUrl = '/quiz/user/played';

        const options: FetchOptions = {
            method: 'GET',
            userToken: userToken || '', // userToken이 없을 시(비로그인) 빈 문자열 전달
        };

        return await postURI(fetchUrl, options);
    });

// 틀린 문제 받아오기
export const getWrongQuiz = createAsyncThunk( 
    '/quiz/user/wrong',
    async () => {
        // firebase에서 userToken받아오기
        let userToken = await auth.currentUser?.getIdToken();

        const fetchUrl = '/quiz/user/wrong';

        const options: FetchOptions = {
            method: 'GET',
            userToken: userToken || '', // userToken이 없을 시(비로그인) 빈 문자열 전달
        };

        return await postURI(fetchUrl, options);
    });

// 퀴즈 검색결과 받아오기
export const searchQuiz = createAsyncThunk(
    '/v2/quiz_search/search/quiz',
    async ({ pageNum, pagingSize, searchText }: searchProps) => {
        // firebase에서 userToken받아오기
        let userToken = await auth.currentUser?.getIdToken();

        const fetchUrl = '/v2/quiz_search/search/quiz';

        const options: FetchOptions = {
            method: 'GET',
            userToken: userToken || '', // userToken이 없을 시(비로그인) 빈 문자열 전달
            data: {
                pageNum: pageNum,
                pagingSize: pagingSize,
                query: searchText,
            },
        };

        return await postURI(fetchUrl, options);
    });

// Id로 퀴즈 전체 내용 받아오기
export const getQuizById = createAsyncThunk(
    '/quiz/play/id',
    async (quizId: string) => {
        // firebase에서 userToken받아오기
        // let userToken = await auth.currentUser?.getIdToken();

        const fetchUrl = `/quiz/play/${quizId}`;

        const options: FetchOptions = {
            method: 'GET',
        };

        return await postURI(fetchUrl, options);
    });

// 주제 검색결과 받아오기
export const searchTopic = createAsyncThunk(
    '/v2/quiz_search/search/topic',
    async ({ pageNum, pagingSize, searchText }: searchProps) => {
        // firebase에서 userToken받아오기
        let userToken = await auth.currentUser?.getIdToken();

        const fetchUrl = '/v2/quiz_search/search/topic';

        const options: FetchOptions = {
            method: 'GET',
            userToken: userToken || '', // userToken이 없을 시(비로그인) 빈 문자열 전달
            data: {
                pageNum: pageNum,
                pagingSize: pagingSize,
                query: searchText,
            },
        };

        return await postURI(fetchUrl, options);
    });


// 관련자료 검색결과 받아오기
export const searchReference = createAsyncThunk(
    '/v2/quiz_search/search/refernce',
    async ({ pageNum, pagingSize, searchText }: searchProps) => {
        // firebase에서 userToken받아오기
        let userToken = await auth.currentUser?.getIdToken();

        const fetchUrl = '/v2/quiz_search/search/refernce';

        const options: FetchOptions = {
            method: 'GET',
            userToken: userToken || '', // userToken이 없을 시(비로그인) 빈 문자열 전달
            data: {
                pageNum: pageNum,
                pagingSize: pagingSize,
                query: searchText,
            },
        };

        return await postURI(fetchUrl, options);
    });