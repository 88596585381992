import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as LogoSvg } from '../../assets/logo_24px.svg';
import { useNavigate } from 'react-router-dom';
import { UserType } from 'model/UserModel';
import { useAppSelector } from 'hooks/hooks';
import { Avatar } from '@mui/material';

/*
* (전역)화면 상단 AppBar
*/
const TopAppBar:React.FC = () => {
    const user:(UserType | null) = useAppSelector((state) => state.userAuth.user);
    const navigate = useNavigate();
  
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      navigate('/settings');
    };
  
    return (
        <AppBar component="nav">
          <Toolbar>
            <IconButton
              size="small"
              edge="start"
              color="inherit"
              aria-label="home"
              sx={{ mr: 1 }}
              href="/home"
            >
              <LogoSvg/>
            </IconButton>
            <Typography variant="body1" component="div" sx={{ flexGrow: 1 }}>
              StartupQT
            </Typography>

            {/* auth 정보가 있을 시 설정 아이콘 표시 */}
            {user?.nickname && (
              <div>
                <Avatar 
                  sx={{ width: 28, height: 28 }}
                  alt="userImage"
                  src={user.profileImage}
                  onClick={handleMenu}
                />
              </div>
            )}
          </Toolbar>
        </AppBar>
    );
};

export default TopAppBar;