import React, { Dispatch, SetStateAction } from "react";

import { 
    Grid, Typography, Divider, TextField, Button, Link, Alert, FormControlLabel, Checkbox
} from "@mui/material";
import QuizAppBar from "components/QuizAppBar/QuizAppBar";
import PasswordInput from "components/PasswordInput/PasswordInput";

interface SignUpViewProps {
    loading: boolean;       // 회원가입 버튼 로딩 여부
    email: string;          // 이메일 변수
    password: string;       // 비밀번호 변수
    checkPassword: string;  // 비밀번호 확인 변수
    nickname: string;       // 사용자 닉네임 변수
    checkBox: boolean;      // 개인정보 동의 체크박스 체크 여부
    errMsg: string;         // 회원가입 오류가 나올 시 띄울 에러메세지
    disabled: boolean;      // 회원가입 버튼의 disabled속성
    setPassword: Dispatch<SetStateAction<string>>;                              // useState 비밀번호 변수 설정 함수
    setCheckPassword: Dispatch<SetStateAction<string>>;                         // useState 비밀번호 확인 변수 설정 함수
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;   // 회원가입 폼 접수 처리 함수
    handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;    // useState 이메일 변수 설정 함수
    handleNicknameChange: (event: React.ChangeEvent<HTMLInputElement>) => void; // useState 닉네임 변수 설정 함수
    handleIsChecked: (event: React.ChangeEvent<HTMLInputElement>) => void;      // useState 체크박스 변수 설정 함수
};

const SignUpView:React.FC<SignUpViewProps> = ({
    loading,
    email,
    password,
    checkPassword,
    nickname,
    checkBox,
    errMsg,
    disabled,
    setPassword,
    setCheckPassword,
    handleSubmit,
    handleEmailChange,
    handleNicknameChange,
    handleIsChecked,
}) => {
    return (
        <>
            <QuizAppBar navType="뒤로가기" navKeyword="뒤로가기" />
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ padding: '56px 16px 72px 16px' }}
            >
                <Grid item component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, maxWidth: "512px" }}>
                    <Typography alignItems="left" variant="subtitle1" sx={{ fontSize: "24px", marginLeft: "0" }}>이메일로 회원가입하기</Typography>
                    <Typography alignItems="left" variant="body2">StartupQT에 오신 것을 환영합니다.</Typography>
                    <Divider sx={{ margin: "24px 0 12px 0" }} />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="이메일"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <PasswordInput
                        password={password}
                        setPassword={setPassword}
                        inputLabel="비밀번호"
                    />
                    <PasswordInput
                        password={checkPassword}
                        setPassword={setCheckPassword}
                        inputLabel="비밀번호 확인"
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        id="nickname"
                        label="닉네임"
                        name="nickname"
                        value={nickname}
                        onChange={handleNicknameChange}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkBox}
                                onChange={handleIsChecked}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={
                            <Typography variant="body2">
                                개인정보 수집·이용에 동의합니다.*
                            </Typography>
                        }
                    />
                    <Alert severity="error" style={{ display: errMsg ? "flex" : "none", marginTop: "8px" }}>
                        {errMsg}
                    </Alert>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: "24px", mb: 2, height: "48px" }}
                        disabled={disabled || loading}
                    >
                        {loading ? "처리 중입니다..." : "회원가입"}
                    </Button>
                    <Grid
                        container
                        width={"100%"}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <Link href="/login/find-password" variant="body2" underline="none" color="#707070">
                                {"비밀번호 찾기"}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" color="#707070">
                                |
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Link href="/signin" variant="body2" underline="none" color="#707070">
                                {"로그인"}
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
};

export default SignUpView;