import { Dispatch } from "react";
import { fetchQuiz } from "redux/modules/API/fetchQuiz";
import { passQuiz } from "redux/modules/cardList";
import { turnToQuiz } from "redux/modules/isQuiz";
import { resetTime } from "redux/modules/quizCouter";

/**
 * 해설 화면에서 홈으로 이동할 시 현재 퀴즈를 다음 퀴즈로 갱신
 * @param dispatch 
 * @param isQuiz 퀴즈 화면인지 해설 화면인지
 */
export async function swipeQuiz(dispatch: Dispatch<any>, isQuiz: boolean) {
    // 퀴즈화면으로 변경
    if (!isQuiz) {
        // 문제 새로 하나 받아온 후 현재 문제 넘기기
        await dispatch(fetchQuiz());
        dispatch(passQuiz());

        // 문제 풀기 시작한 시간 초기화 및 문제 전환
        dispatch(resetTime());
        dispatch(turnToQuiz());
    }
}