import { createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "firebase-config";
import { FetchOptions, fetchQuizQuestions } from "./baseFunction/ApiService";

/**
 * 출석 처리
 */
export const sendAttendance = createAsyncThunk(
    'record/attendance',
    async () => {
      // firebase에서 userToken받아오기
      const userToken = await auth.currentUser?.getIdToken();
  
      // 로그인이 되어있지 않은 상태라면 return
      if (userToken === undefined) return;
  
      const fetchUrl = '/record/attendance';
  
      const options: FetchOptions = {
        method: 'POST',
        userToken: userToken || '', // userToken이 없을 시(비로그인) 빈 문자열 전달
        data: '',
      };
  
      return await fetchQuizQuestions(fetchUrl, options);
  });