import { 
    Grid, Typography, Divider, TextField, Button, Link, Alert, 
} from "@mui/material";

import QuizAppBar from "components/QuizAppBar/QuizAppBar";
import React, { Dispatch, SetStateAction }  from "react";
import PasswordInput from "components/PasswordInput/PasswordInput";

interface LoginProps {
    errMsg: string;     // 에러메세지
    email: string;      // 이메일
    password: string;   // 비밀번호
    setPassword: Dispatch<SetStateAction<string>>;                              // useState의 password를 설정하는 함수
    handleEmailChange: (event: React.ChangeEvent<HTMLInputElement>) => void;    // email 변수에 대한 변경사항을 업데이트하는 함수
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;            // 이메일 로그인 완료 후 "회원가입" 버튼을 눌렀을 때 사용자 로그인을 다루는 함수
}


const LoginView: React.FC<LoginProps> = ({
        errMsg,
        email,
        password,
        setPassword,
        handleSubmit,
        handleEmailChange,
    }) => { 

    return (
        <>
            <QuizAppBar navType="뒤로가기" navKeyword="뒤로가기" />
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ padding: '56px 16px 72px 16px' }}
            >
                <Grid item component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 , maxWidth: "512px"}}>
                    {/* 페이지 소개 */}
                    <Typography alignItems="left" variant="subtitle1" sx={{ fontSize: "24px", marginLeft: "0" }}>이메일로 로그인하기</Typography>
                    <Typography alignItems="left" variant="body2">StartupQT에 오신 것을 환영합니다.</Typography>
                    <Divider sx={{ margin: "24px 0 12px 0" }} />

                    {/* 이메일과 비밀번호 input */}
                    <TextField
                        margin="normal"
                        fullWidth
                        id="email"
                        label="이메일"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={handleEmailChange}
                    />
                    <PasswordInput
                        password={password}
                        setPassword={setPassword}
                        inputLabel="비밀번호"
                    />

                    {/* 이메일 로그인 실패 시 에러메세지가 보임 */}
                    <Alert severity="error" style={{display: errMsg ? "flex" : "none", marginTop: "8px"}}>
                        {errMsg}
                    </Alert>

                    {/* 로그인 버튼 및 비밀번호 찾기, 회원가입 링크 */}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: "24px", mb: 2, height: "48px" }}
                    >
                        로그인
                    </Button>
                    <Grid 
                        container 
                        width={"100%"}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                    >
                        <Grid item>
                            <Link href="/login/find-password" variant="body2" underline="none" color="#707070">
                                {"비밀번호 찾기"}
                            </Link>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" color="#707070">
                                |
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Link href="/signup" variant="body2" underline="none" color="#707070">
                                {"회원가입"}
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default LoginView;