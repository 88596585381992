import React, { useEffect } from 'react';
import { Backdrop, alpha, Box } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { hideOXMarks } from 'redux/modules/cardList';
import { MotionOSVG, MotionXSVG } from './MotionSVG';


/**
 * OX(정답여부) 표시 컴포넌트
 */
const OXmarks: React.FC = () => {
    const isCorrect = useAppSelector(state => state.cardList.isCorrect);
    const showMarks = useAppSelector(state => state.cardList.showOXMarks);
    const dispatch = useAppDispatch();

    // 열린 후 1.2초 후에 닫히게
    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (showMarks) {
          timeoutId = setTimeout(() => {
            dispatch(hideOXMarks());
          }, 1200); // 1.2 seconds
        }
    
        return () => {
          clearTimeout(timeoutId);
        };
    }, [showMarks, dispatch]);

    return (
        <Backdrop
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                background: alpha('#000', 0.1),
                alignItems: 'center',
                justifyContent: 'center'
            }}
            open={showMarks}
            key={showMarks ? "show" : "hide"}
        >
            <Box
                sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
            >
                { isCorrect ? <MotionOSVG/> : <MotionXSVG/> }
            </Box>
        </Backdrop>
    );
};

export default OXmarks;
