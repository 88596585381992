import { Chip } from '@mui/material';
import { QuizTopic } from 'model/QuizModel';
import React from 'react';

interface ChipsProps {
    chipContents: QuizTopic[];
};

/**
 * 칩 컴포넌트
 * @param chipContents 칩에 들어갈 내용 
 * @returns 
 */
const Chips: React.FC<ChipsProps> = ({chipContents}) => {
    return (
        <>
            {
                chipContents.map((item: QuizTopic, index: number) => (
                    <Chip
                        key={index}
                        label={item.topicName}
                        size="small"
                        sx={{ mr: '4px' }}
                    />
                ))
            }
        </>
    );
};

export default Chips;