import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { NavigateNext } from "@mui/icons-material";

interface TitleProps {
    title: string;
    description?: string;
    action?: () => void;
    count?: number; // 0건
    setMaxWidth?: boolean;
}

const Title: React.FC<TitleProps> = ({ title, description, action, count, setMaxWidth=true }) => {
    const handleAction = () => {
        if (action) {
            action();
        }
    };

    return (
        <Box sx={{ width: '100%', maxWidth: setMaxWidth ? '512px' : 'none', paddingBottom: '12px' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {/* 제목 */}
                <Typography alignItems="left" variant="subtitle1" ml={'20px'}>{title}</Typography>

                {/* 더보기 Action */}
                {action && (
                    <Button
                        onClick={handleAction}
                        sx={{ ml: 'auto', mr: '20px', color: '#909094', mt: '26px', whiteSpace: 'nowrap' }}
                        endIcon={<NavigateNext />}
                    >   
                        더보기
                    </Button>
                )}

                {/* 컨텐츠의 수 표시 */}
                {count !== undefined && (
                    <Typography 
                     variant="body2" sx={{ ml: 'auto', mr: '24px', mt: '26px', color: '#909094', whiteSpace: 'nowrap' }}
                    >
                        {count}건
                    </Typography>
                )}
            </Box>

            {/* 부제목 혹은 설명 */}
            {description && 
                <Typography 
                    ml={'20px'} pb={'20px'}
                    alignItems="left" variant="body2" color={'#909094'}
                >
                    {description}
                </Typography>
            }
        </Box>
    );
};

export default Title;