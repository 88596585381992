import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import SchoolIcon from "@mui/icons-material/School";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

/** 업적 target 에 따라 아이콘 리턴 */
const getAchievmentIcon = (
    achievementTarget: string,
    iconSize: string,
) => {
    const iconSx = { width: iconSize, height: iconSize };
    switch (achievementTarget) {
        // 누적 출석
        case "ATTENDANCE":
            return <MilitaryTechIcon sx={ iconSx } />;
        // 누적 풀이 문제 수
        case "TOT_PLAY_COUNT":
            return <SchoolIcon sx={ iconSx } />;
        // 연속 정답
        case "MAX_COMBO":
            return <LocalFireDepartmentIcon sx={ iconSx } />;
        // 연속 출석
        case "SEQ_ATTENDANCE":
            return <EmojiEventsIcon sx={ iconSx } />;
        // 총 풀이 시간
        case "TOT_PLAY_TIME":
            return <HourglassBottomIcon sx={ iconSx } />;
    }
};

export default getAchievmentIcon;