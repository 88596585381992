import { createSlice } from '@reduxjs/toolkit';
import { sendQuizData } from './API/sendQuizData';

interface ConnectProps {
    isConnected: boolean;       // 현재 사용자가 인터넷에 연결되어있는지?
    failedRequest: any[];       // 실패한 요청을 담아두기 위한 변수
};

const initialState: ConnectProps = {
    isConnected: true,
    failedRequest: [],
};

/**
 * 현재 인터넷에 연결되어있는지 확인
 */
export const internetConnect = createSlice({
    name: 'internetConnect',
    initialState,
    reducers: {
        // 인터넷 연결상태 확인
        checkConnect(state){
            state.isConnected = navigator.onLine;
        },
        // 실패한 요청 넣어두기
        keepFailedRequest(state, action){
            state.failedRequest.push(action.payload);
        },
        // 실패한 요청 빼기
        shiftFailedRequest(state){
            state.failedRequest.shift();
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(sendQuizData.fulfilled, (state, action) => {
                state.isConnected = true;

                if(action.payload === undefined) {
                    // 실패한 요청 저장해두기 (중복되지 않게)
                    if(!state.failedRequest.some(obj => JSON.stringify(obj) === JSON.stringify(action.meta.arg))){
                        state.failedRequest.push(action.meta.arg);
                    }
                }
            })
            .addCase(sendQuizData.rejected, (state, action) => {
                state.isConnected = false;
                
                // 실패한 요청 저장해두기 (중복되지 않게)
                if(!state.failedRequest.some(obj => JSON.stringify(obj) === JSON.stringify(action.meta.arg))){
                    state.failedRequest.push(action.meta.arg);
                }
            })
    },
});

export const { checkConnect, keepFailedRequest, shiftFailedRequest } = internetConnect.actions;

export default internetConnect.reducer;