import { createAsyncThunk } from "@reduxjs/toolkit";
import { analytics, auth, logEvent } from "firebase-config";
import { FetchOptions, fetchQuizQuestions } from "./baseFunction/ApiService";

/**
 * 문제 풀이 이력 보내기
 */
interface sendQuizDataProps {
    playStartTime: Date;
    selectExampleId: number;
    isAnswer: boolean;
    quizId: number;
    userId: string | undefined;
};

export const sendQuizData = createAsyncThunk(
    'record/playLog',
    async ({ playStartTime, selectExampleId, isAnswer, quizId, userId }: sendQuizDataProps) => {
        try {
            // firebase에서 userToken받아오기
            const userToken = await auth.currentUser?.getIdToken();
            const startTime = new Date(playStartTime);

            // 로그인이 되어있지 않은 상태라면 return
            if (userToken === undefined) return;

            // 문제 풀이 완료시각
            const playEndTime = new Date();
            const playSpendTime = (playEndTime.getTime() - startTime.getTime()) / 1000;

            // api 요청에 필요한 값들
            const fetchUrl = '/record/play-log';

            const postData = {
                /** 2019-08-24 14:15:22 형태로 변환 */
                playStartTime: startTime
                    .toISOString()
                    .replace("T", " ")
                    .replace(/\..*/, ""),
                playEndTime: playEndTime
                    .toISOString()
                    .replace("T", " ")
                    .replace(/\..*/, ""),

                spandTime: playSpendTime,           // 풀이 시간 (float 초 단위로 셋째자리까지)
                selectExampleId: selectExampleId,   // 선택한 선지의 index
                userInputText: "",                  // 주관식 답. 현재 모든 문제가 객관식이라 해당 없음
                isAnswer: isAnswer,                 // 사용자가 문제를 맞췄는가?
                quizId: quizId,                     // 문제 ID
            };

            const options: FetchOptions = {
                method: 'POST',
                userToken: userToken || '', // userToken이 없을 시(비로그인) 빈 문자열 전달
                data: postData,
            };

            // 서버에서 응답 받아오기
            const response = await fetchQuizQuestions(fetchUrl, options);

            // 애널리틱스) 문제 푸는 사용자 수 - 제출한 시각, 푸는 데 걸린 시간, 접속 사용자 ID 로그
            logEvent(analytics, "user_quiz_solve", {
                checkin_user_id: userId,            // 접속 사용자 ID
                checkin_time_solve: playSpendTime,  // 푸는 데 걸린 시간
                checkin_time_submit: playEndTime,   // 제출 시각
            });

            return response;

        } catch (error) {
            console.log(error);
        }

});