import React, { useEffect } from "react";
import HomeView from "./HomeView";
import { QuizModel } from 'model/QuizModel';
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { UserType } from "model/UserModel";
import { checkDailyAttendance, resetAttendance } from "redux/modules/attendance";
import { auth, analytics, logEvent } from "firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import { openModal } from "redux/modules/modal";
import { turnToQuiz } from "redux/modules/isQuiz";
import { setAchievementData } from "redux/modules/userData";
import { resetTime } from "redux/modules/quizCouter";
import timeFormat from "utils/timeFormat";
import { sendAttendance } from "redux/modules/API/sendAttendance";

const HomeContainer = () => {
    const curQuiz: QuizModel[] = useAppSelector(state => state.cardList.quizList);
    const loading = useAppSelector(state => state.cardList.loading);
    const user: (UserType | null) = useAppSelector(state => state.userAuth.user);
    const todaysFirstAccess = useAppSelector(state => state.attendance.todaysFirstAccess);
    const attendanceTime = useAppSelector(state => state.attendance.attendanceTimeStamp);
    const dispatch = useAppDispatch();

    useEffect(() => {
        // 문제 풀기 시작한 시간 초기화 및 문제로 전환
        dispatch(resetTime());
        dispatch(turnToQuiz());
        
        onAuthStateChanged(auth, async (user) => {
            // 로그인 중일 때
            if (user) {
                // 일일 첫 출석인지 확인
                dispatch(checkDailyAttendance());
            }
        });
    }, []);

    // 일일 첫방문일 시 출석 모달 띄우기
    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            try {                
                // 로그인 중인 사람이 그 날 처음 사이트를 방문했을 때 출석모달 띄우기
                if (user && todaysFirstAccess) {
                    // 일일 학습문제 수 초기화
                    dispatch(resetTime());

                    // 출석이력 전송
                    const response = await dispatch(sendAttendance());

                    // 새로운 업적이 있는지 확인
                    const achievements = response.payload?.earnAchievementIdList;
                    
                    // 새로운 업적이 있으면 업적모달, 없다면 일반 출석모달 띄우기
                    if (achievements.length > 0) {
                        // 업적 ID와 실제 업적 데이터 매칭하기
                        dispatch(setAchievementData(achievements));

                        // 업적모달 열기
                        dispatch(openModal({
                            modalType: "AchievementsModal",
                        }));
                    } else {
                        // 일반 출석모달 띄우기
                        dispatch(
                            openModal({
                                modalType: "AttendanceModal",
                            })
                        );
                    };

                    // 애널리틱스) 첫 방문 이벤트  로깅
                    /**
                     * checkin_user_id: 유저아이디-보안코드-attendance (서버에서 받아온 userId-attendance)
                     * checkin_time: 2024-01-22 17:31:36
                     */
                    logEvent(analytics, 'first_access', {
                        checkin_user_id: (user as unknown as UserType).userId + '-attendance',
                        // 2024-01-22T10:33:42.390Z를 2024-01-22 17:31:36형식으로 변경
                        checkin_time: timeFormat(attendanceTime, true),
                    });

                    // 일일 첫 방문 여부를 false로 변환
                    dispatch(resetAttendance());
                }
            } catch (error) {
                console.log(error);
            }
        });
    }, [todaysFirstAccess])

    return (
        <>
            <HomeView curQuiz={curQuiz} user={user} loading={loading} />
        </>
    )
};

export default HomeContainer;