import { Card, Grid, Pagination, Typography } from "@mui/material";
import Achievements from "components/Achievements/Achievements";
import QuizAppBar from "components/QuizAppBar/QuizAppBar";
import QuizArticle from "components/QuizArticle/QuizArticle";
import Title from "components/Title/Title";
import { mergedAchievementType } from "model/UserDataModel";
import { SearchResultInterface } from "model/searchDetailModel";
import React, { useEffect, useState } from "react";

interface MYDetailContainerProps {
    loading: boolean;
    contentType: string;
    searchResult: SearchResultInterface<mergedAchievementType[]>;
    currentPage: number;
    handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
};

const MYDetailView: React.FC<MYDetailContainerProps> = ({ loading, contentType, searchResult, currentPage, handlePageChange }) => {
    // content type에 따라 다른 컴포넌트를 나타내기 위한 변수
    const [content, setContent] = useState<React.ReactNode | null>(null);

    useEffect(() => {
        // 로딩 중일 때
        if (loading) {
            setContent(<Typography variant="body2" color={'#909090'} sx={{ pt: 2, pb: 4 }}>검색 중입니다...</Typography>);
            return;
        }

        // 검색 결과가 없을 때
        if (searchResult.result.length === 0) {
            setContent(<Typography variant="body2" color={'#909090'} sx={{ pt: 2, pb: 4 }}>검색 결과가 없습니다.</Typography>);
            return;
        }

        // content type에 따라 다른 컴포넌트를 나타냄
        if (contentType === "풀었던 문제") {
            // contentType이 푼 문제일 때
            const quizCards = searchResult.result.map((quiz: any, index: number) => (
                <Card key={index} sx={{ pb: '14px', mb: 2 }} onClick={() => { }}>
                    <QuizArticle quizItems={quiz} isClickable={false} currentUrl={`/MY/history`}/>
                </Card>
            ));
            setContent(quizCards);
        } else if (contentType === "틀린 문제") {
            // contentType이 틀린문제일 때
            const quizCards = searchResult.result.map((quiz: any, index: number) => (
                <Card key={index} sx={{ pb: '14px', mb: 2 }} onClick={() => { }}>
                    <QuizArticle quizItems={quiz} isClickable={false} currentUrl={`/MY/mistake`}/>
                </Card>
            ));
            setContent(quizCards);
        } else if (contentType === "업적") {
            // contentType이 업적일 때
            const referenceCards = <Achievements achievementRes={searchResult.result} setMaxWidth={true} />;
            setContent(referenceCards);
        }
    }, [contentType, searchResult]);



    return (
        <div>
            <QuizAppBar navType="MY" navKeyword="MY" />
            <Grid
                pt={4}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ width: '100%' }}
            >
                <Title title={contentType} />
                {content}

                {
                    searchResult.totalPages > 1 &&
                    <Pagination count={searchResult.totalPages} page={currentPage} onChange={handlePageChange} sx={{ m: '16px 0' }} />
                }
            </Grid>
        </div>
    );
};

export default MYDetailView;