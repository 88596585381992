import React, { useEffect, useState } from "react";
import RefreshIcon from '@mui/icons-material/Refresh';
import { CircularProgress, IconButton, Tooltip, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { fetchQuiz } from "redux/modules/API/fetchQuiz";

/**
 * 퀴즈를 불러올 수 없는 상태일 때 퀴즈 화면 대신 표시
 */
const NoQuiz: React.FC = () => {
    const dispatch = useAppDispatch();
    const currentQList = useAppSelector(state => state.cardList.quizList);
    const isLoading = useAppSelector(state => state.cardList.loading);
    const [loading, setLoading] = useState({
        loading: false,
        loadingMessage: "문제를 불러오지 못했습니다.",
    });

    const tryGetNewQuiz = async() => {

        // 받아와야 할 퀴즈의 개수
        const quizLength = 2 - currentQList.length;

        // 퀴즈가 이미 2개 이상이면 더 받아오지 않음
        if (quizLength <= 0) return;

        // fetchQuiz.. 퀴즈가 2개 이상이 될 때까지 반복
        for (let i = 0; i < quizLength; i++) {
            await dispatch(fetchQuiz());
        };
    };

    // 문제를 불러오는 중일 때 로딩 표시
    useEffect(() => {
        if (isLoading === 'pending') {
            setLoading({
                loading: true,
                loadingMessage: "문제를 불러오는 중입니다...",
            });
        } else {
            setLoading({
                loading: false,
                loadingMessage: "문제를 불러오지 못했습니다.",
            });
        }
    }, [isLoading]);
    
    return (
        <div style={{
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
            }}
        >
            {
                loading.loading ? (
                    <CircularProgress sx={{ opacity: 0.5, marginY: '12px' }} size={16} />
                ) : (
                    <Tooltip title="새로고침" arrow>
                        <IconButton sx={{ opacity: 0.5, width: 'auto' }} onClick={tryGetNewQuiz} aria-label="Get a new Quiz" >
                            <RefreshIcon/>
                        </IconButton>
                    </Tooltip>
                )
            }

            <Typography sx={{ opacity: 0.5 }} variant="body2">{loading.loadingMessage}</Typography>
        </div>
    );
};

export default NoQuiz;