import React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
// import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Paper from '@mui/material/Paper';
import { useLocation } from 'react-router-dom';

const NavBarView:React.FC = () => {
    const pathname = ["/home", "/search", "/league", "/my"];
    const location = useLocation();
    const [value, setValue] = React.useState(pathname.indexOf(location.pathname));

    return(
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="홈" icon={<HomeIcon />} href={pathname[0]} />
          <BottomNavigationAction label="탐색" icon={<SearchIcon />} href={pathname[1]}/>
          {/* <BottomNavigationAction label="리그" icon={<MilitaryTechIcon />} /> */}
          <BottomNavigationAction label="MY" icon={<AssignmentIcon />} href={pathname[3]} />
        </BottomNavigation>
      </Paper>
    );
}
export default NavBarView;