import React, { useState } from "react";
import { 
    InputLabel, FormControl,
    OutlinedInput, InputAdornment, IconButton
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface PasswordInputProps {
    password: string;                       // 입력 비밀번호
    setPassword: React.Dispatch<string>;    // useState에서 password를 업데이트하는 함수
    inputLabel: string;                     // input 안에 들어갈 라벨 텍스트
}

/**
 * 비밀번호 input 컴포넌트
 */
const PasswordInput:React.FC<PasswordInputProps> = ({password, setPassword, inputLabel}) => {
    const [showPassword, setShowPassword] = useState(false);

    // 비밀번호 visability
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };


    return (
        <FormControl fullWidth variant="outlined" sx={{ mt: 1 }}>
            <InputLabel htmlFor="outlined-adornment-password">{inputLabel}</InputLabel>
            <OutlinedInput
                name="password"
                label={inputLabel}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
                autoComplete="current-password"
                value={password}
                onChange={handlePasswordChange}
            />
        </FormControl>
    )
};

export default PasswordInput;