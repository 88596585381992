import React, { useState } from 'react';
import { Button, Typography, Avatar, Grid } from '@mui/material';
import { closeModal } from 'redux/modules/modal';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { useAnimation, motion } from 'framer-motion';
import getAchievmentIcon from 'components/Achievements/getAchievementIcon';


/**
 * 업적모달
*/
// 업적 아이콘 사이즈
const iconSize = "64px";

// 업적 아이콘을 감싸는 사각형 style
const avatarStyle = {
    width: 88,
    height: 88,
    backgroundColor: '#f9fafd',
    color: '#1268B3',
    borderRadius: "25px",

    boxShadow: `0px 2px 4px 2px rgba(0, 0, 0, 0.15)`,
    filter: `dropShadow(0px 2px 2px rgba(0, 0, 0, 0.3))`,
};

const AchievementsModal: React.FC = () => {
    const dispatch = useAppDispatch();
    // 현재 이미지 및 텍스트의 인덱스 상태
    const [currentIndex, setCurrentIndex] = useState(0);

    // 사용자의 업적
    const fullAchievementData = useAppSelector(state => state.userData.achievements);

    // Framer Motion의 애니메이션 제어를 위함
    const controls = useAnimation();
    const iconControls = useAnimation();
    const handleClickNext = async () => {
        // Typography 사라지는 효과
        await Promise.all([
        controls.start({ x: -60, opacity: 0 }),
        iconControls.start({ opacity: 0 }),
        ]);

        // 다음 인덱스 계산 및 상태 업데이트
        const newIndex: number = (currentIndex + 1);
        setCurrentIndex(newIndex);

        // Typography 나타나는 효과
        await Promise.all([
        controls.start({ x: 0, opacity: 1 }),
        iconControls.start({ opacity: 1 }),
        ]);
    };

    // 모달 닫기
    const handleClick = () => {
        // 모달 닫기
        dispatch(closeModal());
    };

    const prev = () => {
        // 다음 인덱스 계산 및 상태 업데이트
        const newIndex: number = (currentIndex - 1);
        setCurrentIndex(newIndex);
    };


    return (
        <>            
            {/* 아이콘 */}
            <div style={{textAlign: 'center'}}>
                {/* 업적획득 title */}
                <Typography variant="h6" pt={2} pb={2}>🏆 업적 획득 🏆</Typography>

                {/* 업적 아이콘 */}
                <motion.div 
                    key={currentIndex}
                    animate={iconControls}
                    style={{display: 'flex', justifyContent: 'center'}}
                >
                    <Avatar alt="Game Badge" sx={avatarStyle}>
                        {getAchievmentIcon(fullAchievementData[currentIndex].target, iconSize)}
                    </Avatar>
                </motion.div>

                {/* 업적 내용 */}
                <motion.div
                    animate={controls}
                >
                    <Typography variant="body1" pt={2} >{fullAchievementData[currentIndex].name}</Typography>
                    <Typography variant="body2" pt={1} whiteSpace={"pre-wrap"}>
                        {fullAchievementData[currentIndex].description}, 대단해요!
                    </Typography>
                </motion.div>

                {/* pagination: 업적이 2개 이상일 때만 노출 */}
                {fullAchievementData.length > 1 &&
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '12px' }}>
                        {fullAchievementData.map((_: any, index: number) => (
                            <div
                                key={index}
                                style={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    margin: 5,
                                    backgroundColor: currentIndex === index ? '#1268B3' : 'gray',
                                    border: 'none',
                                }}
                            ></div>
                        ))}
                    </div>
                }
            </div>

            {/* 확인 버튼 */}
            <Button
                variant="contained"
                disableElevation
                fullWidth
                sx={{ borderRadius: "12px", padding: "12px", marginBottom: "12px", marginTop: "12px" }}
            >
                <label
                    style={{ width: "100%", fontSize: "14px" }}
                    onClick={ currentIndex === fullAchievementData.length - 1 ? handleClick : handleClickNext}
                >
                    {currentIndex === fullAchievementData.length - 1 ? '확인' : '다음'}
                </label>
            </Button>
        </>
    );
};

export default AchievementsModal;