import React, { useEffect, useState } from 'react';
import { Divider, Button, Typography, FormControl, RadioGroup, FormControlLabel, Radio, TextField } from '@mui/material';
import { closeModal } from 'redux/modules/modal';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { reportProps } from 'model/reportProps';
import { sendErrorReport } from 'redux/modules/API/sendErrorReport';


const ErrorReportModal: React.FC = () => {
    // 현재 퀴즈의 ID
    const quizId = useAppSelector(state => state.cardList.quizList[0].quizId);
    const dispatch = useAppDispatch();
    
    // form data 저장용 useState 변수
    const [formData, setFormData] = useState<reportProps>({
        reportType: '',             // 오류 유형(오타, 문제오류, 기타)* : string
        reportText: '',             // 세부사유 : string
        quizId: quizId,             // 퀴즈 ID* : integer
        referenceId: -1,            // 참고 자료 ID (참고 자료 신고의 경우)
    });

    // 현재 reference id
    // 현재 관련자료 drawer가 열려있으면 관련자료 오류리포트 창을 연 것으로 판단.
    const currentTopicState = useAppSelector(state => state.topicList);

    useEffect(()=>{
        if (currentTopicState.drawerOpened){
            setFormData({
                reportType: '',
                reportText: '',
                referenceId: currentTopicState.topicList[-1].id,
            });
        } else {
            setFormData({
                reportType: '',
                reportText: '',
                quizId: quizId,
            });
        };
    },[]);

   
    // form data 값 저장
    const handleFormChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [field]: event.target.value,
        });
    };

    // 오류리포트 전송 후 모달 닫기
    const handleClick = async () => {
        try {
            // console.log(formData);
            // 문제 오류 전송(수정 필요)
            await dispatch(sendErrorReport(formData));
            alert('오류 전송이 정상적으로 처리되었습니다.');
            // console.log('Form Values:', formData);
            
            // 모달 닫기
            dispatch(closeModal());
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            {/* 서비스 간단 소개 */}
            <Typography variant="h6" pt={2} pb={1}>🚨 오류 신고</Typography>
            <Divider />
            <Typography variant="body2" pt={1.5} whiteSpace={"pre-wrap"}>
                오류 유형과 세부 사유를 작성해 주세요.
            </Typography>
            {/* 오류 유형 */}
            <FormControl style={{ paddingTop: "20px", width: "100%" }}>
                <RadioGroup
                    value={formData.reportType}
                    onChange={handleFormChange('reportType')}
                    sx={{ paddingBottom: "16px" }}
                >
                    <FormControlLabel value="오타" control={<Radio size='small' />} label="오타" />
                    <FormControlLabel value="문제오류" control={<Radio size='small' />} label="문제오류" />
                    <FormControlLabel value="기타" control={<Radio size='small' />} label="기타" />
                </RadioGroup>
            </FormControl>
            <TextField
                label="세부 사유"
                value={formData.reportText}
                onChange={handleFormChange('reportText')}
                multiline
                fullWidth
                style={{ marginBottom: "24px" }}
            />

            {/* 확인 버튼 */}
            <Button
                variant="contained"
                disableElevation
                fullWidth
                sx={{ borderRadius: "12px", padding: "12px", marginBottom: "12px" }}
            >
                <label
                    style={{ width: "100%", fontSize: "14px" }}
                    onClick={handleClick}
                >
                    작성완료
                </label>
            </Button>
        </>
    );
};

export default ErrorReportModal;