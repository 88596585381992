import * as React from 'react';
import { Divider, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { closeModal } from 'redux/modules/modal';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { swipeQuiz } from 'utils/swipeQuiz';



const GoHomeModal: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isQuiz: boolean = useAppSelector((state) => state.isQuiz.quiz);

    // 닫기버튼 눌렀을 때
    const handleClose = async() => {
        // 모달 닫기
        dispatch(closeModal());

        // 해설 화면에서 홈으로 이동할 시 현재 퀴즈를 다음 퀴즈로 갱신
        swipeQuiz(dispatch, isQuiz);

        // 홈으로 이동
        navigate("/");
    };

    // 계속하기
    const handleKeepGoing = () => {
        // 모달 닫기
        dispatch(closeModal());
    }

    return (
        <>
                <Typography variant="h6" pt={2} pb={1}>학습 종료</Typography>
                <Divider />
                <Typography variant="body2" pt={1.5} pb={4} whiteSpace={"pre-wrap"}>
                    맞춤 문제는 홈화면에서 이어서 풀 수 있어요.<br/>
                    홈으로 돌아가시겠어요?
                </Typography>

                {/* 버튼 */}
                <Button
                    variant="contained"
                    fullWidth
                    disableElevation 
                    sx={{ borderRadius: "12px", padding: "12px" }}
                    onClick={handleClose}
                >
                    <label style={{ width: "100%" }}>
                        홈으로
                    </label>
                </Button>
                <Button
                    variant="outlined"
                    disableElevation
                    fullWidth
                    sx={{ borderRadius: "12px", padding: "12px", marginY: "12px"}}
                    onClick={handleKeepGoing}
                >
                    <label style={{ width: "100%" }}>
                        계속풀기
                    </label>
                </Button>
        </>
    );
};

export default GoHomeModal;