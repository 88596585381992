import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GoogleAuthProvider, User, UserCredential, getAdditionalUserInfo } from 'firebase/auth';
import { auth, signInWithEmailAndPassword, signInWithPopup } from 'firebase-config';
import { AuthState, UserType, UserInfo, GoogleLoginReturnType } from 'model/UserModel';
import { PURGE } from 'redux-persist';
import { getUserStat } from './API/getUserStat';

// Firebase 이메일 로그인
export const signInUser = createAsyncThunk(
  'auth/signInUser',
  async ({ email, password }: { email: string; password: string }, { rejectWithValue }) => {
    try {
      const userCredential: UserCredential = await signInWithEmailAndPassword(auth, email, password);

      // 로그인 유저 info
      const user: User = userCredential.user;

      const userInfo: UserInfo = {
        email: user.email || '',
        uid: user.uid || '',
      };

      return userInfo;
    } catch (error: any) {
      return rejectWithValue({ errorCode: error.code, errorMessage: error.message });
    }
  }
);

// Firebase Google 로그인
export const signInGoogle = createAsyncThunk(
  'auth/signInGoogle',
  async () => {
    try{      
      const res = await signInWithPopup(auth, new GoogleAuthProvider());   // 구글 로그인
      const isNewUser = await getAdditionalUserInfo(res);   // 신규유저 여부확인

      // 로그인 유저 info
      const user: User = res.user;
  
      const userInfo: GoogleLoginReturnType = {
        info: {
          email: user.email,
          uid: user.uid,
        },
        displayName: user.displayName,
        photoURL: user.photoURL,
        isNewUser: isNewUser?.isNewUser,
      }
      return userInfo;
    }catch(error: any){
      return error;
    }
  }
);


// 유저 부가정보 초기상태
const initialUser: UserType = {
  profileImage: '',
  userId: '',
  nickname: '',
  userLevel: 0,
  currentXp: 0,
  isWritable: false,
  isActivate: false,
}

// 유저 초기 상태 정의
const initialState: AuthState = {
  user: initialUser,
  userInfo: null,
  errorCode: null,
  errorMessage: null,
};

// Slice 생성
const userAuth = createSlice({
  name: 'userAuth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // 이메일로그인 성공
      .addCase(signInUser.fulfilled, (state, action) => {
        state.userInfo = action.payload;
        const nickname = auth.currentUser?.displayName;
        const profileImg = auth.currentUser?.photoURL;

        // 닉네임 설정
        if (state.user && nickname) {
          state.user.nickname = nickname;
        } else if (state.user && nickname==="") {
          state.user.nickname = "익명";
        }

        // 프로필 이미지 설정
        if (state.user && profileImg) {
          state.user.profileImage = profileImg;
        }

        state.errorCode = null;
        state.errorMessage = null;
      })
      // 이메일로그인 실패
      .addCase(signInUser.rejected, (state, action) => {
        const payload = action.payload as { errorCode?: string; errorMessage?: string; };

        if (payload && payload.errorCode && payload.errorMessage) {
          console.log(payload.errorCode);
          state.errorCode = payload.errorCode;
          state.errorMessage = payload.errorMessage;
        } else {
          state.errorCode = 'UNKNOWN_ERROR_CODE';
          state.errorMessage = 'Unknown error occurred';
        }
      })
      // 구글로그인 성공
      .addCase(signInGoogle.fulfilled, (state, action) => {
        state.userInfo = action.payload.info;
        const profileImg = action.payload.photoURL;
        
        // 닉네임 설정
        if (state.user) {
          state.user.nickname = action.payload.displayName;
        }

        // 프로필 이미지 설정
        if (state.user && profileImg) {
          state.user.profileImage = profileImg;
        }
        
        state.errorCode = null;
        state.errorMessage = null;
      })
      // 구글로그인 실패
      .addCase(signInGoogle.rejected, (state, action) => {
        const payload = action.payload as { errorCode?: string; errorMessage?: string; };

        if (payload && payload.errorCode && payload.errorMessage) {
          console.log(payload.errorCode);
          state.errorCode = payload.errorCode;
          state.errorMessage = payload.errorMessage;
        } else {
          state.errorCode = 'UNKNOWN_ERROR_CODE';
          state.errorMessage = 'Unknown error occurred';
        }
      })
      // 유저 stat 받아오기
      .addCase(getUserStat.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      // 로그아웃
      .addCase(PURGE, () => initialState);
  },
});

export default userAuth.reducer;
