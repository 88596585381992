import { createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "firebase-config";
import { FetchOptions, fetchQuizQuestions, postURI } from "./baseFunction/ApiService";

/**
 * 퀴즈 받아오기(로그인, 비로그인)
 */
export const fetchQuiz = createAsyncThunk('play/getQuiz', async () => {
    // firebase에서 userToken받아오기
    const userToken = await auth.currentUser?.getIdToken();
    let fetchUrl: string;
    let options: FetchOptions = {
      method: 'GET',
      userToken: userToken || '', // userToken이 없을 시(비로그인) 빈 문자열 전달
    };

    // 비로그인 시 문제 처리 ----------------
    if (userToken === undefined) {
        fetchUrl = '/quiz/play/guest';
      
        return await fetchQuizQuestions(fetchUrl, options);
    }
  
    // 로그인 시 추천 quizId를 받아와서 추천문제 주기 -------
    // 1. 추천 퀴즈 받아오기
    fetchUrl = '/v2/recommend/recommend/quizzes/';
    options.data = {num_quizzes: 1};
    const recommendQuizId = await postURI(fetchUrl, options);

    // 2. id로 퀴즈 받아오기
    fetchUrl = `/quiz/play/${recommendQuizId.recommended_quizzes.recommendedQuizzes[0].quizId}`;
    options.data = '';
    return await fetchQuizQuestions(fetchUrl, options);
  });