import { useEffect, useState } from "react";
import SearchView from "./SearchView";
import { searchQuiz, searchReference, searchTopic } from "redux/modules/API/getSearchResults";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { QuizModel } from "model/QuizModel";
import { useNavigate, useParams } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { setSearchKeyword } from "redux/modules/searchData";
import { openModal } from "redux/modules/modal";

interface searchResultInterface {
    topic: string[],
    quiz: QuizModel[],
    reference: string[],
}

const SearchContainer:React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const {keyword} = useParams();
    const searchKeyword = useAppSelector(state => state.searchData.searchKeyword);
    const isLogin = useAppSelector(state => state.userAuth.userInfo);

    const [searchText, setSearchText] = useState<string>('');               // 검색 중인 키워드 저장용 상태
    const [searchResultText, setSearchResultText] = useState<string>('');   // 검색한 키워드
    const [loading, setLoading] = useState(false);                // 검색 중인지 확인용 상태

    const goToSearchPage = (searchResultText: string, searchType?: string) => {
        // 더보기 페이지로 이동
        if (searchType) {
            navigate(`/search/${searchType}/${searchResultText}`);
        } else {
        // 키워드 검색 페이지로 이동
            navigate(`/search/${searchResultText}`);
        }
    };

    // 검색 결과 받아오기
    const searchResult:searchResultInterface = {
        topic: [],
        quiz: [],
        reference: [],
    }
    const [getSearchResult, setGetSearchResult] = useState(searchResult);


    // 검색 실행 함수
    const executeSearch = async(searchText: string) => {
        try {            
            // 검색 키워드 저장
            setSearchResultText(searchText);
            setSearchText(searchText);

            // 새로운 키워드로 검색했을 시 기존 검색 결과 초기화
            if (searchKeyword !== searchText) {
                dispatch(setSearchKeyword(searchText));
            }
    
            // 검색 결과 받아오기
            const quizSearchResponse = await dispatch(searchQuiz({ pageNum: 1, pagingSize: 5, searchText: searchText }));
            const topicSearchResponse = await dispatch(searchTopic({ pageNum: 1, pagingSize: 8, searchText: searchText }));
            const referenceSearchResponse = await dispatch(searchReference({ pageNum: 1, pagingSize: 5, searchText: searchText }));

            // 검색 결과 저장
            setGetSearchResult({
                topic: topicSearchResponse.payload.results.results.map((result:any) => result.topicName),
                quiz: quizSearchResponse.payload.results.results,
                reference: referenceSearchResponse.payload.results.results.map((result:any) => result.referenceData),
            });

            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {
        // 검색 결과 초기화
        const auth = getAuth();
        setLoading(true);

        const listener = onAuthStateChanged(auth, (user) => {
            if (user && keyword !== undefined) {
                user.getIdToken().then(async () => {
                    await executeSearch(keyword.toString());
                });
            }
        });

        if (isLogin === null) {
            dispatch(
                openModal({
                    modalType: "LoginModal",
                    data: "검색",
                })
            );
            return;
        }

        return () => {
            listener();
        }
    } , [dispatch, keyword]);

    // Enter 키 입력 시 검색 실행
    const handleEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            navigate(`/search/${e.currentTarget.value}`);
        }
    };

    // 검색 키워드 입력 시 상태 저장
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(e.target.value);
    };

    // 검색 결과 초기화
    const clearSearchResult = () => {
        setSearchResultText('');
        setSearchText('');
        setGetSearchResult(searchResult);
        navigate('/search');
    };

    return (
        <SearchView 
            loading={loading}
            handleEnterPress={handleEnterPress}
            handleInputChange={handleInputChange}
            clearSearchResult={clearSearchResult}
            searchResultText={searchResultText}
            searchText={searchText}
            getSearchResult={getSearchResult}
            goToSearchPage={goToSearchPage}
        />
    );
};

export default SearchContainer;