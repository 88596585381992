import { Button, Grid } from '@mui/material';
import backgroundImage1 from '../../assets/SearchButtonImg/container(1).png';
import backgroundImage2 from '../../assets/SearchButtonImg/container(2).png';
import backgroundImage3 from '../../assets/SearchButtonImg/container(3).png';
import backgroundImage4 from '../../assets/SearchButtonImg/container(4).png';
import backgroundImage5 from '../../assets/SearchButtonImg/container(5).png';
import backgroundImage6 from '../../assets/SearchButtonImg/container(6).png';
import backgroundImage7 from '../../assets/SearchButtonImg/container(7).png';
import backgroundImage8 from '../../assets/SearchButtonImg/container(8).png';
import React, { SetStateAction, useEffect, useState } from 'react';

const backgroundImageArray = [
    backgroundImage1,
    backgroundImage2,
    backgroundImage3,
    backgroundImage4,
    backgroundImage5,
    backgroundImage6,
    backgroundImage7,
    backgroundImage8,
];


interface ButtonWithBackgroundProps {
    text: string[];
    onClick: (buttonText: string) => void;
};


const ButtonWithBackground: React.FC<ButtonWithBackgroundProps> = ({ text, onClick }) => {
    const [shuffledBackgrounds, setShuffledBacgrounds] = useState<string[]>([]);
    const buttonStyle = {
        width: '100%',
        height: '72px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '12px',
        color: 'white',
    };

    // 버튼 배경을 랜덤하게 섞는 함수
    const shuffleArray = (array: string[]) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    useEffect(() => {
        // 배경 이미지를 랜덤하게 섞음
        setShuffledBacgrounds(shuffleArray(backgroundImageArray));
    }, []);

    return (
        <Grid container spacing={'10px'} maxWidth={'512px'} px={2}>
            {text.map((buttonText, index) => (
                <Grid item xs={6} sm={4} key={index}>
                    <Button
                        sx={{
                            ...buttonStyle,
                            backgroundImage: `url(${shuffledBackgrounds[index % shuffledBackgrounds.length]})`,
                        }}
                        onClick={() => onClick(buttonText)}
                    >
                        {buttonText}
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
};

export default ButtonWithBackground;