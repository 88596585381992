import React from "react";
import LoginModalView from "./LoginModalView";
import { useNavigate } from "react-router-dom";
import { signInGoogle } from "redux/modules/userAuth";
import { closeModal } from "redux/modules/modal";
import { useAppDispatch } from "hooks/hooks";
import { getUserStat } from "redux/modules/API/getUserStat";

interface LoginModalProps {
    data?: string;  // 모달에 넘겨줄 데이터
};

/*
* 로그인 모달
*/
const LoginModalContainer:React.FC<LoginModalProps> = ({ data }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // 구글로그인 연결
    const handleGoogleLogin = async() => {
        try {
            // 구글 로그인
            const userInfo = await dispatch(signInGoogle());

            // 서버에서 사용자 데이터 받아온 후 저장하기
            await dispatch(getUserStat());

            // 모달 닫고 홈으로 이동
            dispatch(closeModal());


            // 신규유저일 경우 목적설정 페이지로 이동, 아니라면 홈으로 이동
            if (userInfo.payload.isNewUser) {
                navigate('/purpose-setting');
            } else {
                navigate('/');
            }
        } catch (error: any) {
            console.log(error);
        }
    };

    // 닫기버튼 눌렀을 때
    const handleClose = () => {
        dispatch(closeModal());
    };

    // 로그인 페이지로 이동
    const handleEmailLogin = () => {
        dispatch(closeModal());
        navigate("/login");
    };

    return(
        <LoginModalView 
            handleClose={handleClose} 
            handleEmailLogin={handleEmailLogin} 
            handleGoogleLogin={handleGoogleLogin} 
            data={data}
        />
    )
};

export default LoginModalContainer;