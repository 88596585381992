import { createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "firebase-config";
import { FetchOptions, fetchQuizQuestions } from "./baseFunction/ApiService";

/**
 * 서버로부터 사용자 상태 받아오기
 */
export const getUserStat = createAsyncThunk(
    '/user/stat',
    async () => {
    // firebase에서 userToken받아오기
    const userToken = await auth.currentUser?.getIdToken();
  
    const fetchUrl = '/user/stat';
  
    const options: FetchOptions = {
      method: 'GET',
      userToken: userToken || '', // userToken이 없을 시(비로그인) 빈 문자열 전달
    };
  
    return await fetchQuizQuestions(fetchUrl, options);
  });