import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import MY from "pages/MY";
import ExplanationPage from "pages/ExplanationPage";
import QuizPage from "pages/QuizPage";
import Login from "pages/Login";
import Settings from "pages/Settings";
import Topic from "components/Topic/Topic";
import GlobalModal from "components/modals/GlobalModal/GlobalModal";
import FindPassword from "pages/FindPassword/FindPassword";
import SignUp from "pages/SignUp";
import StartSlider from "pages/StartSlider";
import PurposeSetting from "pages/PurposeSetting";
import OXmarks from "components/OXmarks/OXmarks";
import Search from "pages/Search";
import SearchDetail from "pages/SearchDetail";
import QuizSearch from "pages/QuizSearch";
import MYDetail from "pages/MYDetail";

const AppRouter = () => {
    // 사이트 첫 방문인지 확인
    const isFirstVisit = localStorage.getItem('visited');

    return(
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/home/*" element={<Home/>} />
                    <Route path="/my" element={<MY/>} />
                    <Route path="/my/:type" element={<MYDetail/>} />
                    <Route path="/my/:type/quizpage/:id" element={<QuizSearch/>} />
                    <Route path="/my/:type/explanationpage/:id" element={<ExplanationPage/>} />
                    <Route path="/explanation/*" element={<ExplanationPage/>} />
                    <Route path="/quiz/*" element={<QuizPage/>} />
                    <Route path="/Login" element={<Login/>} />
                    <Route path="/Login/find-password" element={<FindPassword/>} />
                    <Route path="/signup" element={<SignUp/>} />
                    <Route path="/Settings/*" element={<Settings/>} />
                    <Route path="/start" element={<StartSlider/>}/>
                    <Route path="/search" element={<Search/>}/>
                    <Route path="/search/:keyword" element={<Search/>}/>
                    <Route path="/search/:type/:keyword" element={<SearchDetail/>}/>
                    <Route path="/purpose-setting" element={<PurposeSetting/>}/>
                    <Route path="/search/:keyword/quizpage/:id" element={<QuizSearch/>}/>
                    <Route path="/search/quiz/:keyword/quizpage/:id" element={<QuizSearch/>}/>
                    <Route path="/search/:keyword/explanationpage/:id" element={<ExplanationPage/>}/>

                    {/* 사이트 첫 방문일 시 서비스소개화면으로 이동 */}
                    <Route path="/" caseSensitive={false}
                        element = {isFirstVisit? <Home/> : <Navigate to="/start" replace/>}
                    />
                </Routes>
                {/* 관련자료 컴포넌트 */}
                <Topic/>

                {/* 글로벌 모달 */}
                <GlobalModal/>

                {/* 정오 확인 */}
                <OXmarks/>
            </BrowserRouter>
        </>
    );
};

export default AppRouter;