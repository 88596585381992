import React from 'react';
import { Card, CardHeader, CardActions, Avatar, IconButton, Button, Tooltip } from '@mui/material';
import { QuizModel } from 'model/QuizModel';
import IndexTypo from 'components/IndexTypo';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import QuizArticle from 'components/QuizArticle/QuizArticle';


interface QuizProps {
  quizContent: QuizModel;     // 퀴즈 데이터
  isHome?: boolean;           // 홈화면인지?
  disableClick: boolean;      // 선지를 클릭했을 때 backgroundColor를 변경시킬지 (현재 문제에서 클릭한 선지가 다음 문제의 선지에 반영되지 않게 하기 위함)
  handleNavigate: () => void; // 문제 풀이 후 해설화면으로 이동할 때
  handleErrorReport: () => void; // 오류신고 버튼을 눌렀을 때
  isSelected: boolean;        // 선지가 선택된 상태인지
}

const QuizView: React.FC<QuizProps> = ({ quizContent, isHome, disableClick, handleNavigate, handleErrorReport, isSelected }) => {
  // 문제제공자 정보(임시)
  let providerColor = "#BBBBBB";
  const providerName = "The innovators";

  return (
    <Card sx={{ pb: '14px' }} >
      <CardHeader
        // 문제를 작성한 유저의 프로필이미지
        avatar={
          <Avatar sx={{ bgcolor: providerColor }} aria-label={providerName}>
            {providerName[0]}
          </Avatar>
        }

        // 문제를 작성한 유저의 닉네임
        title={providerName}

        // 문제 상단 인터랙션 (북마크, 오류 리포트)
        action={
          <>
            {/* 북마크버튼(추후 추가) */}
            {/* <IconButton aria-label="BookmarkBorderIcon">
            <BookmarkBorderIcon />
          </IconButton>           */}
            <Tooltip title="오류신고" arrow>
              <IconButton
                aria-label="WbTwilightIcon"
                onClick={handleErrorReport}
              >
                <WbTwilightIcon />
              </IconButton>
            </Tooltip>
          </>
        }
      />

      {/* 카테고리, 문제, 예문 */}
      <QuizArticle quizItems={quizContent} />

      {/* 선지 */}
      <IndexTypo quizContent={quizContent} disableClick={disableClick} />

      {/* 정답확인 버튼 (Home화면일 때만 나타남) */}
      <CardActions
        sx={{ display: isHome ? "in-line" : "none" }}
      >
        <Button
          aria-label="check answers"
          variant="text" sx={{ ml: 'auto', mr: '16px' }}
          onClick={() => handleNavigate()}
          disabled={!isSelected}
        >
          정답확인
        </Button>
      </CardActions>
    </Card>
  );
}

export default QuizView;