import { createSlice } from "@reduxjs/toolkit";
import { getAchievements } from "./modules/API/getAchievements";
import { mergedAchievementType } from "model/UserDataModel";
import pickAchievementData from "utils/pickAchievementData";


interface MyPageDataState {
    dailyGoal: number;                // 일일 목표
    achievements: mergedAchievementType[];  // 업적 데이터
    loading: boolean;                 // 데이터 로딩 상태
};

const initialState: MyPageDataState = {
    dailyGoal: 5,
    achievements: [],
    loading: false,
};


/**
 * 마이페이지 데이터 슬라이스
 * - 업적 데이터 관리
 * - 업적 데이터 로딩 상태 관리
*/
const myPageData = createSlice({
    name: 'data',
    initialState,
    reducers: {
      changeDailyGoal: (state, action) => {
        state.dailyGoal = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(getAchievements.pending, (state) => {
          // 데이터 로딩 중 상태 업데이트
          state.loading = true;
        })
        .addCase(getAchievements.fulfilled, (state, action) => {
          // 데이터 로딩 완료 상태 업데이트
          state.loading = false;
          state.achievements = pickAchievementData(action.payload.achievementsList);
        })
        .addCase(getAchievements.rejected, (state, action) => {
          // 데이터 로딩 실패 상태 업데이트
          state.loading = false;
          console.log(action.error.message);
        });
    },
  });

  export const { changeDailyGoal } = myPageData.actions;
  export default myPageData.reducer;