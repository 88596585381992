import { Card, CardContent, Typography } from "@mui/material";
import Chips from "components/Chips/Chips";
import CustomMarkdown from "components/CustomMarkdown/CustomMarkdown";
import { QuizModel } from "model/QuizModel";
import React from "react";
import { useNavigate } from "react-router-dom";

interface QuizArticleProps {
  quizItems: QuizModel;   // 문제 정보
  isClickable?: boolean;  // 카드 클릭 가능 여부
  currentUrl?: string;    // 현재 URL
}

const QuizArticle: React.FC<QuizArticleProps> = ({ quizItems, isClickable = false, currentUrl }) => {
  const navigate = useNavigate();
  
  // 카드 클릭 시 문제풀이 페이지로 이동
  const handleCardClick = () => {
    if (!isClickable || !currentUrl) return;
    navigate(`${currentUrl}/quizpage/${quizItems.quizId}`);
  };

  return (
    <CardContent onClick={handleCardClick}>
      {/* 카테고리 */}
      <Chips chipContents={quizItems.quizTopics} />

      {/* 문제 */}
      <CardContent sx={{ display: 'flex', alignItems: 'top', padding: '8px 4px 12px 4px' }}>
        <Typography variant="body1" pr={2} >Q</Typography>
        <CustomMarkdown content={quizItems.questionText} />
      </CardContent>

      {/* 예문 */}
      {quizItems.descriptionText &&
        <CardContent sx={{ p: 0 }}>
          <Card variant="outlined" color="on-surfact-varient" sx={{ p: 2 }}>
            <CustomMarkdown content={quizItems.descriptionText} />
          </Card>
        </CardContent>
      }
    </CardContent>
  );
};

export default QuizArticle;