import * as React from 'react';
import { Divider, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { closeModal } from 'redux/modules/modal';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';


const AttendanceModal: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const streak = useAppSelector(state => state.attendance.attendanceStreak);
    const streakMessage = (streak===1) ? 
        `1일 차 출석입니다. 앞으로 꾸준히 접속해 볼까요?` 
        :
        `${streak}일 차 출석입니다. 창업전문가가 머지않았네요! 오늘도 함께 공부해 볼까요?`

    // 모달 닫기
    const handleClick = () => {
        // 모달 닫고 홈으로 이동
        dispatch(closeModal());
        navigate('/');
    }

    return (
        <>
            {/* 일일출석 title */}
            <Typography variant="h6" pt={2} pb={1}>📰 일일 출석</Typography>
            <Divider />
            <Typography variant="body2" pt={1.5} pb={4} whiteSpace={"pre-wrap"}>
                {streakMessage}
            </Typography>

            {/* 확인 버튼 */}
            <Button
                variant="contained"
                disableElevation
                fullWidth
                sx={{ borderRadius: "12px", padding: "12px", marginBottom: "12px"}}
            >
                <label
                    style={{ width: "100%", fontSize: "14px" }}
                    onClick={handleClick}
                >
                    좋아요😊
                </label>
            </Button>
        </>
    );
};

export default AttendanceModal;