// 요청 보낼 URL
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export type FetchOptions = {
  method: 'GET' | 'POST' | 'DELETE' | 'PUT';  // HTTP Method
  userToken?: string;                         // 유저가 로그인 중일 시 헤더에 추가할 token
  data?: any;                                 // 필요햘 경우 서버로 보낼 데이터
};


/**
 * API 요청 분리를 위한 함수
 * @param url base url을 제외한 나머지 url
 * @param options get, post, delete, put 중 하나의 method, userToken(선택), data(선택)
 * @returns json 형태의 응답
 */
export const fetchQuizQuestions = async (url: string, options: FetchOptions) => {

  // HTTP Method, userToken(선택), data(선택)
  const { method, userToken, data } = options;

  // 최종 URL
  const fetchUrl = API_BASE_URL + url;

  // 헤더 설정
  const headers: Record<string, string> = {
    accept: 'application/json',
    'Content-Type': 'application/json', // POST 요청일 때 Content-Type 설정
  };

  // user가 로그인 중일 시 헤더에 token 추가
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  const requestOptions: RequestInit = {
    method,
    headers,
    body: data ? JSON.stringify(data) : undefined, // 데이터가 있을 경우 body에 추가
  };

  // 응답 받아오기
  const response = await fetch(fetchUrl, requestOptions);

  if (!response.ok) {
    throw new Error('네트워크 응답을 받아오는 데 실패하였습니다.');
  }

  return await response.json();
};

/**
 * API 요청 분리를 위한 함수. url에 데이터를 실어서 보냄
 * @param url base url을 제외한 나머지 url
 * @param options get, post, delete, put 중 하나의 method, userToken(선택), data(선택)
 * @returns json 형태의 응답
 */
export const postURI = async (url: string, options: FetchOptions) => {
  
  // HTTP Method, userToken(선택), data
  const { method, userToken, data } = options;

  // 최종 URL
  const fetchUrl = API_BASE_URL + url;

  const queryString = new URLSearchParams(data);

  // 헤더 설정
  const headers: Record<string, string> = {
    accept: 'application/json',
    'Content-Type': 'application/json', // POST 요청일 때 Content-Type 설정
  };

  // user가 로그인 중일 시 헤더에 token 추가
  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`;
  }

  const requestOptions: RequestInit = {
    method,
    headers,
  };

  // 응답 받아오기
  const response = await fetch(`${fetchUrl}?${queryString}`, requestOptions);

  if (!response.ok) {
    throw new Error('네트워크 응답을 받아오는 데 실패하였습니다.');
  }

  return await response.json();
};