import React from "react";
import { SwipeableDrawer, Box, Typography, Chip, Card, CardHeader, CardContent, IconButton } from '@mui/material';

import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import WbTwilightIcon from '@mui/icons-material/WbTwilight';
import ShareIcon from '@mui/icons-material/ShareOutlined';
import { useNavigate } from 'react-router-dom';

import { useAppSelector, useAppDispatch } from "hooks/hooks";
import { topic } from "model/topicListModel";
import { getTopic, handleDrawerOpen } from "redux/modules/topicList";
import CustomMarkdown from "components/CustomMarkdown/CustomMarkdown";



/*
* 관련자료 팝업
*/
const Topic: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const topicList: topic[] = useAppSelector((state) => state.topicList.topicList);
    const drawerOpened: boolean = useAppSelector((state) => state.topicList.drawerOpened);

    const toggleDrawer = (open: boolean) => () => {
        dispatch(handleDrawerOpen(open));
    };

    const handleChipClick = (item: topic) => {
        dispatch(getTopic(item));
    };

    const ChipContent: JSX.Element[] = topicList.map((item: topic, index: number) => (
        <Chip 
            key={index}
            label={item.content}
            sx={{ mr: '4px', mb: '8px'}}
            onClick={()=>{handleChipClick(item)}}
        />
    ));

    const tempContent: string = "[니치마케팅](/test/24913)이란 타 기업이 점유하고 있는 시장 이외의 곳을 찾아서 경영자원을 집중적으로 투하하는 마케팅전략입니다. 니치(niche)란 틈새를 의미하는 말로서 ‘남이 모르는 좋은 낚시터’ 라는 은유적인 뜻이 있습니다. 대중시장 붕괴 후의 세분화한 시장 또는 소비상황을 설명하는 말이기도 합니다.";

    return (
        <SwipeableDrawer
            anchor='bottom'
            open={drawerOpened}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            swipeAreaWidth={56}
            disableSwipeToOpen={true}
            ModalProps={{
                keepMounted: true,
            }}
        >
            <Box
                sx={{
                    px: 2,
                    borderTopLeftRadius: 24,
                    borderTopRightRadius: 24,
                }}
            >
                <Typography color={"#FFF"} pl={'4px'} pb={'4px'}>최근 본 자료</Typography>
                {ChipContent}
            </Box>
            <Box
                sx={{
                    px: 2,
                    pb: 2,
                    borderTopLeftRadius: 24,
                    borderTopRightRadius: 24,
                    background: "#FFFFFF",
                }}
            >
                <CardHeader
                    // 관련자료 Topic
                    title={
                        <Typography alignItems="left" variant="body1" >{topicList.at(-1) ? topicList[topicList.length - 1].content : "관련자료"}</Typography>
                    }

                    // 문제 상단 인터랙션 (북마크, 오류 리포트)
                    action={
                        <Box>
                            <IconButton aria-label="BookmarkBorderIcon">
                                <BookmarkBorderIcon />
                            </IconButton>
                            <IconButton aria-label="ShareIcon" sx={{pl: "12px"}}>
                                <ShareIcon />
                            </IconButton>
                            <IconButton aria-label="WbTwilightIcon" sx={{pl: "12px"}}>
                                <WbTwilightIcon />
                            </IconButton>
                        </Box>
                    }
                    sx={{ pr: "2px" }}
                />
                {/* 관련자료 content */}
                <CardContent sx={{ px: "8px", pt: "0" }}>
                    <Card variant="outlined" color="on-surfact-varient" sx={{ p: 2 }}>
                        <CustomMarkdown content={tempContent}/>
                    </Card>
                </CardContent>
            </Box>
        </SwipeableDrawer>
    );
};

export default Topic;