import { createSlice } from '@reduxjs/toolkit';

const initialState: {quiz: boolean} = {
    quiz: true, // 문제화면인지? (true: 문제, false: 해설)
};

/**
 * 현재 퀴즈화면인지 아닌지를 quiz변수로 나타냄
 */
export const isQuiz = createSlice({
    name: 'isQuiz',
    initialState,
    reducers: {
        // 퀴즈화면으로 넘어갈 때
        turnToQuiz(state) {
            state.quiz = true;
        },
        // 퀴즈화면이 아닌 곳으로 넘어갈 때
        turnToExplanation(state){
            state.quiz = false;
        }
    },
});

export const { turnToQuiz, turnToExplanation } = isQuiz.actions;

export default isQuiz.reducer;