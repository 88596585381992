import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { CounterQuiz } from 'model/QuizModel';
import { RootState } from 'redux/store/store';


const initialState: CounterQuiz = {
    maxCount: 5,               // 단위학습 내 문제 수
    count: 0,                   // 현재 문제 count
    playStartTime: new Date(),  // 퀴즈를 풀기 시작한 시간
};

export const quizCounter = createSlice({
    name: 'quizCounter',
    initialState,
    reducers: {
        // 문제 count 0으로 초기화
        resetCount(state) {
            state.count = 0;
        },

        // 문제 시작 시간 reset
        resetTime(state) {
            state.playStartTime = new Date();
        },

        // 문제 풀고난 뒤 문제 count 올리기
        increaseCount(state) {
            if (state.count < state.maxCount){
                state.count += 1;
            }
        },

        // 단위학습 내 문제 수 변경
        setMaxCount(state, action: PayloadAction<number>){
            state.maxCount = action.payload;
        }
    },
});

export const { resetCount, resetTime, increaseCount, setMaxCount } = quizCounter.actions;

export const selectCount = (state: RootState) => state.quizCounter.count;

export default quizCounter.reducer;