import * as React from 'react';
import { Divider, Button, Typography, Grid, Card } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { closeModal } from 'redux/modules/modal';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';

import answerImg from '../../assets/correctAnswer_35x40.png';
import timeImg from '../../assets/time_35x40.png';
import { resetTime } from 'redux/modules/quizCouter';


const AnalyzeModal: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const MAX: number = useAppSelector(state => state.quizCounter.maxCount);
    const dailyUnitStudy: number = useAppSelector(state => state.attendance.dailyUnitStudy);
    const count = useAppSelector(state => state.quizCounter.count);
    // width가 390 이하일 시 사용자 문제풀이 분석 grid direction을 column으로 설정
    const itemDirection = window.innerWidth <= 380 ? 'column' : 'row';


    // 닫기버튼 눌렀을 때
    const handleClose = () => {
        // 모달 닫고 홈으로 이동
        dispatch(closeModal());

        // 문제풀이 시간 초기화
        dispatch(resetTime());
        navigate("/");
    };

    // 계속하기
    const handleKeepGoing = () => {
        // 모달 닫기
        dispatch(closeModal());

        // 문제풀이 시간 초기화
        dispatch(resetTime());
    }

    return (
        <>
                <Typography variant="h6" pt={2} pb={1}>단위 학습 완료 🎉</Typography>
                <Divider />
                <Typography variant="body2" pt={1.5} pb={4} whiteSpace={"pre-wrap"}>
                    벌써 {MAX}문제를 모두 풀었어요.<br/>
                    이어서 하시겠어요?
                </Typography>

                {/* 사용자 문제풀이 분석 */}
                <Grid container direction="row" spacing={1}>
                    <Grid item xs={6}>
                        <Card variant="outlined" color="on-surfact-varient" sx={{ p: 2 }}>
                            <Grid container direction={itemDirection} spacing={2} >
                                {window.innerWidth && 
                                    <Grid item>
                                        <img src={answerImg} height={"100%"}/> 
                                    </Grid>
                                }
                                <Grid item>
                                    <Typography variant='body2' color={'#909094'}>
                                        일일풀이<br/>
                                    </Typography>
                                    <Typography variant='body1'>
                                        {dailyUnitStudy * MAX + count} 개
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card variant="outlined" color="on-surfact-varient" sx={{ p: 2 }}>
                            <Grid container direction={itemDirection} spacing={2} >
                                {window.innerWidth&& 
                                    <Grid item>
                                        <img src={timeImg} height={"97%"}/> 
                                    </Grid>
                                }
                                <Grid item>
                                    <Typography variant='body2' color={'#909094'}>
                                        단위학습
                                    </Typography>
                                    <Typography variant='body1'>
                                        {dailyUnitStudy} 번째
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>

                {/* 버튼 */}
                <Button
                    variant="contained"
                    disableElevation
                    fullWidth
                    sx={{ borderRadius: "12px", padding: "12px", marginY: "12px"}}
                >
                    <label
                        style={{ width: "100%", fontSize: "14px" }}
                        onClick={handleKeepGoing}
                    >
                        계속하기
                    </label>
                </Button>
                <Button
                    variant="outlined"
                    fullWidth
                    disableElevation 
                    sx={{ borderRadius: "12px", padding: "12px" }}
                    onClick={handleClose}
                >
                    <label style={{ width: "100%" }}>홈으로</label>
                </Button>
        </>
    );
};

export default AnalyzeModal;