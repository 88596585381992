import * as React from 'react';
import { Divider, Button, Typography, Link } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import kakaotalkLogo from '../../../assets/kakao_login_original.png';
import GoogleLogo from '../../../assets/Google-Button.png';

interface OpenProps {
    handleClose: () => void;        // 닫기버튼 눌렀을 때
    handleEmailLogin: () => void;   // 이메일 로그인 버튼을 눌렀을 때
    handleGoogleLogin: () => void;  // 구글 로그인 버튼을 눌렀을 때
    data?: string;                   // 모달에 넘겨줄 데이터
};


const LoginModalView: React.FC<OpenProps> = ({ handleClose, handleEmailLogin, handleGoogleLogin, data }) => {
    
    return (
        <>
            {/* 서비스 간단 소개 */}
            <Typography variant="h6" pt={2} pb={1}>맞춤형 학습 시작하기</Typography>
                { data ?
                <Typography variant="body2" pb={4}>
                    {data} 기능은 로그인 후 사용할 수 있어요. <br/>
                    로그인 후 StartupQT의 맞춤학습을 경험하세요.
                </Typography>
                :
                <Typography variant="body2" pb={4}>
                    현재 회원님의 학습정보가 저장되어있지 않아요.<br/>
                    로그인 후 StartupQT의 맞춤학습을 경험하세요.
                </Typography>
                }
            <Divider />
            <Typography variant="body2" pt={4} pb={1.5}>
                🤔 무엇을 어떻게 배울지 고민하지 않아도 돼요.
            </Typography>
            <Typography variant="body2">
                👍 사용자 맞춤 문제를 주제별로 제공해요.
            </Typography>
            <Typography variant="body2" pt={1.5} pb={4}>
                🔥 실시간으로 내 실력을 확인해요.
            </Typography>

            {/* 로그인 버튼 */}
            <Button
                variant="contained"
                disableElevation
                fullWidth
                sx={{ background: "#FFFFFF", borderRadius: "12px", padding: "12px", marginBottom: "12px", border: "1px solid #BBBBBB" }}
            >
                <img src={GoogleLogo} style={{ height: "18px", width: "18px" }} />
                <label
                    style={{ width: "100%", color: "#000000", fontSize: "14px" }}
                    onClick={handleGoogleLogin}
                >
                    구글 로그인
                </label>
            </Button>
            {/* <Button 
                        variant="contained" 
                        disableElevation 
                        fullWidth
                        sx={{ background:"#FEE500", borderRadius: "12px", padding: "12px", marginBottom:"12px"}}
                    >
                        <img src={kakaotalkLogo} style={{height:"18px", width: "18px"}}/>
                        <label style={{width:"100%", color:"#000000", fontSize:"14px"}}>카카오 로그인</label>
                    </Button> */}
            <Button
                variant="contained"
                fullWidth
                disableElevation sx={{ borderRadius: "12px", padding: "12px" }}
                onClick={handleEmailLogin}
            >
                <EmailIcon sx={{ height: "18px", width: "18px" }} />
                <label style={{ width: "100%" }}>이메일 로그인</label>
            </Button>

            {/* 나중에 로그인 버튼 */}
            <div style={{width:"100%", textAlign:"center", marginTop:"12px"}}>
                <Link color="#707070" variant="body2" onClick={handleClose}>
                    나중에 할래요
                </Link>
            </div>
        </>
    );
};

export default LoginModalView;