import React, { useRef, useEffect, useState } from "react";
import QuizSearchView from "./QuizSearchView";
import { QuizModel } from 'model/QuizModel';
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { useMotionValue } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { addSearchQuiz, passSearchQuiz } from "redux/modules/searchData";
import { getQuizById } from "redux/modules/API/getSearchResults";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { turnToQuiz } from "redux/modules/isQuiz";

const height: number = 168;
const padding: number = 16;

function useConstraints(currentCardHeight: number): [constraints: { top: number; bottom: number }, totalHeight: number] {
    const [constraints, setConstraints] = useState({ top: 0, bottom: 0 });
    const [totalHeight, setTotalHeight] = useState(currentCardHeight + height + padding);

    // app Bar Height, 상단 Typography Height, 2번째카드에서 노출되는 height(변경가능)
    const upperContainers = 56 + 92 + 240;

    // 콘텐츠가 들어갈 containter의 높이
    const contentContainer = window.innerHeight - upperContainers;

    useEffect(() => {
        const contentHeight = currentCardHeight + height + padding;

        if (currentCardHeight > contentContainer) {
            // 콘텐츠가 들어갈 높이보다 card높이가 길면 삐져나간 부분만큼 스크롤이 가능하게 함
            setConstraints({ top: contentContainer - currentCardHeight, bottom: 0 });
            setTotalHeight(contentHeight);
        } else {
            // 콘텐츠가 들어갈 높이보다 card높이가 짧으면 위로 스크롤할 수 없도록.
            setConstraints({ top: 0, bottom: 0 });

            if (contentContainer > contentHeight) {
                setTotalHeight(contentHeight + (contentContainer - contentHeight));
            }
        }

        if (totalHeight < window.innerHeight) {
            setTotalHeight(window.innerHeight - 56 - 92);
        }
    }, [currentCardHeight]);

    return [constraints, totalHeight];
}

const QuizSearchContainer: React.FC = () => {
    const { keyword, id } = useParams();
    const quizList: QuizModel[] = useAppSelector(state => state.searchData.searchQuizList);
    const loading = useAppSelector(state => state.searchData.loading);
    
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // 현재 퀴즈의 카드 높이
    const [currentCardHeight, setCurrentCardHeight] = useState(0);
    const currentCardRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // 현재 문제카드의 높이 설정
        if (currentCardRef.current) {
            setCurrentCardHeight(currentCardRef.current?.clientHeight);
        };

        // 현 페이지를 퀴즈 페이지로 설정
        dispatch(turnToQuiz());
    }, [quizList]);

    // 스크롤을 위한 y변수
    const y = useMotionValue(0);

    // 현 퀴즈의 카드 높이에 따라 스크롤 제한
    const { top, bottom } = useConstraints(currentCardHeight)[0];
    const totalHeight = useConstraints(currentCardHeight)[1];


    // 문제를 넘겼을 때
    const onDelete = async () => {
        try {
            if (keyword === undefined || id === undefined) return;
            dispatch(passSearchQuiz());

            // 문제 풀기 시작한 시간 초기화
            // dispatch(resetTime());

            const newQuiz = await dispatch(getQuizById(id));
            dispatch(addSearchQuiz(newQuiz.payload[0]));

        } catch (error) {
            alert('문제 받아오기에 실패했습니다. 홈으로 이동합니다.');
            navigate('/');
        }
    };


    /**
     * 인터넷 연결 관리
     */
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        // 검색 결과 초기화
        const auth = getAuth();

        const listener = onAuthStateChanged(auth, (user) => {
            if (navigator.onLine && user && keyword !== undefined) {
                user.getIdToken().then(async () => {
                    if (id === undefined) return;
                    const newQuiz = await dispatch(getQuizById(id));
                    
                    await dispatch(passSearchQuiz());
                    dispatch(addSearchQuiz(newQuiz.payload.resultQuiz));
                });
            }

            // 인터넷 연결 상태 변경 이벤트 리스너 등록
            window.addEventListener('online', handleOnlineStatusChange);
            window.addEventListener('offline', handleOnlineStatusChange);
        });

        return () => {
            listener();
            window.removeEventListener('online', handleOnlineStatusChange);
            window.removeEventListener('offline', handleOnlineStatusChange);
        }

    }, [keyword, id]);


    // 인터넷 연결 상태 변경을 감지하고 액션을 디스패치하는 함수
    const handleOnlineStatusChange = async () => {
        if (navigator.onLine) {
            setSnackbarMessage('연결되었습니다.');
            setSnackbarOpen(true);

        } else {
            setSnackbarMessage('인터넷 연결이 끊겼습니다.');
            setSnackbarOpen(true);
        }
    };

    // 스낵바 닫힘 처리
    const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };


    return (
        <>
            <QuizSearchView
                currentCardRef={currentCardRef}
                quizList={quizList}
                top={top}
                bottom={bottom}
                y={y}
                onDelete={onDelete}
                height={totalHeight}
                padding={padding}
                loading={loading}
                keyword={keyword}

                // 스낵바
                snackbarOpen={snackbarOpen}
                handleSnackbarClose={handleSnackbarClose}
                snackbarMessage={snackbarMessage}
            />
        </>
    )
};

export default QuizSearchContainer;