import { GlobalStyles, ThemeProvider } from "@mui/material";
import AppRouter from 'AppRouter';
import { theme } from "./constants/theme";


function App() {
  const globalBackgroundColor = '#F4F3F7';

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{
        body: { backgroundColor: globalBackgroundColor }
      }} />
      <div className="App" style={{ overflowX: "hidden" }}>
        <AppRouter />
      </div>
    </ThemeProvider>
  );
}

export default App;