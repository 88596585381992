import React, { useEffect } from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import NavigateNext from '@mui/icons-material/NavigateNext';
import QuizAppBar from "components/QuizAppBar/QuizAppBar";

interface SettingsProps {
    handleLogout: () => void;   // firebase 로그아웃 처리 함수
    handleQuit: () => void;     // firebase 탈퇴 처리 함수
}

const SettingsView: React.FC<SettingsProps> = ({ handleLogout, handleQuit }) => {

    return (
        <>
            <QuizAppBar navType="뒤로가기" navKeyword="뒤로가기" />
            <List sx={{ width: '100%', maxWidth: 512, pt: "72px" }}>
                {/* 로그아웃 */}
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={handleLogout}
                    >
                        <ListItemText sx={{ pl: "16px" }} primary={`로그아웃`} />
                        {/* <IconButton aria-label="Navto">
                            <NavigateNext />
                        </IconButton> */}
                    </ListItemButton>
                </ListItem>

                {/* 서비스 탈퇴 */}
                <ListItem disablePadding>
                    <ListItemButton
                        onClick={handleQuit}
                    >
                        <ListItemText sx={{ pl: "16px" }} primary={`서비스 탈퇴`} />
                    </ListItemButton>
                </ListItem>
            </List>
        </>
    )
}

export default SettingsView;