import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { getAchievements } from "redux/modules/API/getAchievements";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { mergedAchievementType } from "model/UserDataModel";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import AchievementFullCard from "./AchievementFullCard";

interface AchievementsProps {
    achievementRes?: mergedAchievementType[];
    setMaxWidth?: boolean;
}
/**
 * 사용자의 업적을 보여주는 컴포넌트
 * @returns 사용자의 업적
 */
const Achievements: React.FC<AchievementsProps> = ({ achievementRes }) => {
    const dispatch = useAppDispatch();

    // 업적 데이터 가져오기
    const achievements: mergedAchievementType[] | null = useAppSelector((state) => state.myPageData.achievements);
    const [data, setData] = useState<mergedAchievementType[]>([]);

    // 업적 데이터 가져오기. auth정보가 바뀔 때마다 실행
    useEffect(() => {
        const auth = getAuth();

        const listener = onAuthStateChanged(auth, async (user) => {
            if (user && !achievementRes) {
                user.getIdToken().then(async () => {
                    await dispatch(getAchievements({pageNum: 1, pagingSize: 5}));
                });
            }
        });

        return () => {
            listener();
        };
    }, [dispatch]);


    // 업적 데이터가 바뀔 때마다 업적 데이터 업데이트
    useEffect(() => {
        if(achievements) {
            setData(achievements);
        }
    }, [achievements]);

    return (
        <Grid
            container
            direction="row"
            spacing={1}
            sx={{ 
                paddingBottom: '8px', paddingRight: '16px',
                display: 'flex', flexDirection: 'column',
                alignItems: 'center', justifyContent: 'center',
            }}
            pl={2}
        >
            {/* 사용자가 모은 업적 */}
            {data && data.map((achievement: mergedAchievementType) => (
                    <AchievementFullCard 
                        achievement={achievement} 
                        achievementNum={achievement.achievement_id} 
                        key={achievement.achievement_id} 
                    />
                ))
            }
        </Grid>
    );
};

export default Achievements;