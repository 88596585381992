import React from "react";
import styled from "styled-components";
import { AnimationControls, Variants, motion } from 'framer-motion';
import { images, startTypo } from "./StartSliderContainer";
import { Button, Typography, Link } from '@mui/material';
import TopAppBar from "components/TopAppBar/TopAppBar";

interface StartSliderProps {
    controls: AnimationControls;            // motion animation control 변수
    currentIndex: number;                   // 현재 슬라이드의 index
    imgVariants: Variants;                  // 이미지 슬라이드가 들어오고 나가는 효과
    handleClickStart: () => void;           // 마지막 슬라이드에서 서비스 홈으로 이동
    handleClickNext: () => Promise<void>;   // 슬라이드 넘기기
};

// 상단 typography 높이
const TYPO_TOP = '84px';
const TYPO_HEIGHT = '80px';

// 하단 button 높이
const BUTTONS_BOTTOM = '40px';
const BUTTONS_HEIGHT = '102px';

// 중간 이미지 높이
const StyledImg = styled(motion.img)`
    max-height: calc(100vh - ${TYPO_TOP} - ${TYPO_HEIGHT} - ${BUTTONS_BOTTOM} - ${BUTTONS_HEIGHT});
`;

const StartSliderView:React.FC<StartSliderProps> = ({
    controls,
    currentIndex,
    imgVariants,
    handleClickStart,
    handleClickNext
}) => {
    return (
        <div style={{ overflow: "hidden"}}>
            <TopAppBar />
            <div style={{
                display: 'flex',
                position: 'relative',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                margin: '0px 16px',
            }}>
                {/* 서비스 설명 */}
                <motion.div
                    style={{
                        position: 'absolute',
                        opacity: 1,
                        top: TYPO_TOP,
                        width: '100%',
                        maxWidth: '512px',
                        height: TYPO_HEIGHT,
                    }}
                    animate={controls}
                >
                    <Typography variant="h5" mb={1}>
                        {startTypo[currentIndex].title}
                    </Typography>
                    <Typography variant="body2">
                        {startTypo[currentIndex].description[0]}
                    </Typography>
                    <Typography variant="body2">
                        {startTypo[currentIndex].description[1]}
                    </Typography>
                </motion.div>


                {/* 서비스 설명 이미지 */}
                    <StyledImg
                        key={currentIndex}
                        src={images[currentIndex]}
                        alt={`Image ${currentIndex}`}
                        variants={imgVariants}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                        transition={{ duration: 0.5 }}
                    />


                <div style={{
                    position:'absolute', 
                    bottom: BUTTONS_BOTTOM, 
                    width: '100%', 
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: "12px",
                }}>
                    {/* pagination */}
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        {startTypo.map((_: any, index: number) => (
                            <div
                                key={index}
                                style={{
                                    width: 8,
                                    height: 8,
                                    borderRadius: '50%',
                                    margin: 5,
                                    backgroundColor: currentIndex === index ? '#1268B3' : 'gray',
                                    border: 'none',
                                }}
                            ></div>
                        ))}
                    </div>

                    {/* 다음, 건너뛰기 버튼 */}
                    { currentIndex === startTypo.length - 1 ? 
                            <Button
                            variant="contained"
                            onClick={handleClickStart}
                            sx={{ width: '100%', maxWidth: '512px' }}
                            >
                                시작하기
                            </Button>
                        :
                            <Button
                                variant="contained"
                                onClick={handleClickNext}
                                sx={{ width: '100%', maxWidth: '512px' }}
                            >
                                다음
                            </Button>
                    }
                    <Link color="#707070" onClick={handleClickStart}>
                        건너뛰기
                    </Link>
                </div>
            </div>
        </div>
    )
};

export default StartSliderView;