import React, { useState } from 'react';
import { Divider, Button, Typography, Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { closeModal } from 'redux/modules/modal';

import { auth } from "firebase-config";
import { persistor } from "redux/store/store";
import deleteAuth from 'utils/deleteAuth';
import PasswordInput from 'components/PasswordInput/PasswordInput';
import { reauthenticate } from 'utils/reauthenticate';
import { useAppDispatch } from 'hooks/hooks';


const QuitModal: React.FC = () => {
    const providerData = auth.currentUser?.providerData;

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');

    let userAuthType: string | null = null; // userAuthType을 초기화

    providerData &&
        providerData.length > 0 &&
        providerData.forEach((userInfo) => {
            if (userInfo.providerId === 'password') {
                // 이메일로그인일 시 userAuthType에 password저장
                userAuthType = 'password';
            } else if (userInfo.providerId === 'google.com') {
                // 구글로그인일 시 userAuthType에 google저장
                userAuthType = 'google';
            }
        });

    // 닫기버튼 눌렀을 때
    const handleClose = () => {
        dispatch(closeModal());
    };

    // 탈퇴
    const handleLogout = async () => {
        const success = await reauthenticate(password);
        if (success === 'success') {
            try {    
                // firebase Auth 삭제
                await deleteAuth();
    
                // 저장된 정보 초기화
                await persistor.purge();
    
                // localStorage 정보 초기화
                localStorage.clear();
    
                // 모달 닫고 홈으로 이동
                handleClose();
                alert('탈퇴완료되었습니다.');
                navigate('/');
            } catch (err) {
                console.log(err);
            }
        } else {
            setErrMsg(success);
        }
        
    };

    return (
        <>
            {/* 서비스 탈퇴 안내 */}
            <Typography variant="h6" pt={2} pb={1}>서비스 탈퇴</Typography>
            <Divider />

            {/* 비밀번호 입력 */}
            {userAuthType === 'password' ?
                <>
                    <Typography variant="body2" pt={1.5} pb={2} whiteSpace={"pre-wrap"}>
                        탈퇴 하시면 저장된 정보가 모두 삭제됩니다.<br />
                        계속하시려면 비밀번호를 입력하세요.
                    </Typography>
                    <PasswordInput
                        password={password}
                        setPassword={setPassword}
                        inputLabel="비밀번호"
                    />
                    <Alert severity="error" style={{ display: errMsg ? "flex" : "none", marginTop: "8px" }}>
                        {errMsg}
                    </Alert>
                </>
                :
                <Typography variant="body2" pt={1.5} pb={2} whiteSpace={"pre-wrap"}>
                    탈퇴 하시면 저장된 정보가 모두 삭제됩니다.<br />
                    서비스 탈퇴 버튼을 누르시면 재로그인 후 탈퇴처리됩니다.
                </Typography>
            }


            {/* 탈퇴 버튼 */}
            <Button
                variant="contained"
                disableElevation
                fullWidth
                // 이메일 로그인일 시 password를 치지 않으면 탈퇴 불가
                disabled={ (userAuthType==="password" && !password) ? true : false}
                sx={{ background: "#ce1f1f", borderRadius: "12px", padding: "12px", marginBottom: "12px", marginTop: "24px" }}
            >
                <label
                    style={{ width: "100%", fontSize: "14px" }}
                    onClick={handleLogout}
                >
                    서비스 탈퇴
                </label>
            </Button>
            <Button
                variant="outlined"
                fullWidth
                disableElevation
                sx={{ borderRadius: "12px", padding: "12px" }}
                onClick={handleClose}
            >
                <label style={{ width: "100%" }}>돌아가기</label>
            </Button>
        </>
    );
};

export default QuitModal;