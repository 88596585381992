import { createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "firebase-config";
import { FetchOptions, postURI } from "./baseFunction/ApiService";
import { reportProps } from "model/reportProps";

/**
 * 오류리포트 전송
 */
export const sendErrorReport = createAsyncThunk(
    'v2/report_error/',
    async (reportData: reportProps) => {
      // firebase에서 userToken받아오기
      const userToken = await auth.currentUser?.getIdToken();
  
      // 로그인이 되어있지 않은 상태라면 return
      if (userToken === undefined) return;
  
      const fetchUrl = '/v2/report_error/';
  
      const options: FetchOptions = {
        method: 'POST',
        userToken: userToken || '', // userToken이 없을 시(비로그인) 빈 문자열 전달
        data: reportData,
      };
  
      await postURI(fetchUrl, options);
  });