import { User } from "firebase/auth";
import { auth, deleteUser } from "../firebase-config";
/**
 * firebase 계정 삭제
 */
async function deleteAuth() {
  let isSuccess = false;
  const user: User|null = auth.currentUser;

  if (user) {
      await deleteUser(user)
        .then((res) => {
          // 계정삭제 성공
          isSuccess = true;
        })
        .catch((error) => {
          //계정삭제 실패
          isSuccess = false;

          console.log(error);
        });
  }
  return isSuccess;
}
export default deleteAuth;