import { Avatar, Box, Card, Grid, Typography } from "@mui/material";
import getAchievmentIcon from "./getAchievementIcon";
import { mergedAchievementType } from "model/UserDataModel";

// 업적 아이콘 사이즈
const iconSize = "40px";

// 업적 아이콘을 감싸는 사각형 style
const avatarStyle = {
    width: 72,
    height: 72,
};

interface AchievementCardProps {
    achievement: mergedAchievementType;
    achievementNum: string;
}


/**
 * 각 업적의 카드 컴포넌트
 * @param param0 업적 데이터
 * @returns  업적 카드
 */
const AchievementFullCard:React.FC<AchievementCardProps> = ({ achievement, achievementNum }) => {
    return (
        <Grid item key={achievementNum} sx={{ width: "100%", maxWidth: "512px"}}>
            <Card
                className="achievement-card"
                variant="outlined"
                sx={{
                    display: 'flex', flexDirection: 'row',
                    alignItems: 'center', justifyContent: 'left',
                    height: '120px',
                    gap: 3,
                    pl: 3,
                    background: achievement.isClear ? '#fefefe' : '#f9f9f9',
                }}
            >
                {/* 업적 아이콘 */}
                <div className="achievement-card__image" >
                    <Avatar
                        alt="Game Badge"
                        sx={{
                                ...avatarStyle,
                                backgroundColor: achievement.isClear ? '#f9fafd' : '#efefef',
                                color: achievement.isClear ? '#1268B3' : '#d9d9d9',
                            }}
                    >
                        {getAchievmentIcon(achievement.target, iconSize)}
                    </Avatar>
                </div>

                {/* 업적 이름 */}
                <Box>
                    <Typography alignItems="center" variant="body1" color={achievement.isClear ? '': "#d6d6d6"}>{achievement.name}</Typography>
                    <Typography alignItems="center" variant="body2" color={achievement.isClear ? "#b4b4b4": "#e3e3e3"} >{achievement.description}</Typography>
                </Box>
            </Card>
        </Grid>
    );
};

export default AchievementFullCard;