import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { QuizModel } from 'model/QuizModel';
import quiz from "../../mockData/quiz.json";
import nextQuiz from "../../mockData/nextQuiz.json";
import { fetchQuiz } from './API/fetchQuiz';

interface CardList {
    quizList: QuizModel[];      // 퀴즈 리스트 (현재, 다음 퀴즈)
    isSelected: boolean;        // 사용자가 선지를 선택했는지?
    selectedIndex: number;      // 선택한 선지의 index
    typo: string | undefined;   // 상단에 표시할 Typography
    isCorrect: boolean;         // 사용자가 문제를 맞췄는가?
    showOXMarks: boolean;       // OX표시 컴포넌트를 보여줄 것인가?
    loading: 'pending' | 'succeeded' | 'failed' | 'idle' // 문제 fetch 상태 
};

const initialState: CardList = {
    quizList: [quiz.resultQuiz, nextQuiz.resultQuiz],
    isSelected: false,
    selectedIndex: -1,
    typo: "",
    isCorrect: false,
    showOXMarks: false,
    loading: 'idle',
};

export const waitAndHideOXMarks = createAsyncThunk(
    'oxMarks/waitAndHide',
    async (_, { dispatch }) => {
        dispatch(cardList.actions.showOXMarks());
        return new Promise<boolean>(resolve => {
          setTimeout(() => {
            resolve(false);
          }, 2000);
        });
    }
  );

export const cardList = createSlice({
    name: 'cardList',
    initialState,
    reducers: {
        // 문제 넘기기
        passQuiz(state) {
            state.quizList.shift();

            // 인덱스 초기화
            state.selectedIndex = -1;
            state.isSelected = false;
        },

        // 사용자가 고른 선지 저장
        getSelection(state, action: PayloadAction<number>) {
            state.isSelected = true;
            state.selectedIndex = action.payload;
        },

        // 정오 여부에 따라
        getOX(state, action: PayloadAction<QuizModel>) {
            const currentQuiz = action.payload;
            try {
                if (currentQuiz.examples[state.selectedIndex].isAnswer) {
                    //정답일 시
                    state.typo = "✅ 정답입니다.";
                    state.isCorrect = true;
                } else {
                    // 오답일 시
                    state.typo = "❌ 오답입니다."
                    state.isCorrect = false;
                }

                state.isSelected = false;
            } catch (error) {
                console.log(error);
            }
        },
        // OX 표시를 2초 간 보여줌
        showOXMarks: (state) => {
            state.showOXMarks = true;
        },
        hideOXMarks: (state) => {
            state.showOXMarks = false;
        },
    },

    extraReducers: (builder) => {
        builder
            // 문제 가져오기 성공
            .addCase(fetchQuiz.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.quizList.push(action.payload.resultQuiz);
            })
            .addCase(fetchQuiz.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchQuiz.rejected, (state) => {
                state.loading = 'failed';
            })
        },
});

export const { passQuiz, getSelection, getOX, showOXMarks, hideOXMarks } = cardList.actions;

export default cardList.reducer;