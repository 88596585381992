import { createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "firebase-config";
import { FetchOptions, postURI } from "./baseFunction/ApiService";

/**
 * 서버로부터 사용자 상태 받아오기
 */
export const getAchievements = createAsyncThunk(
    '/user/profile/achievements',
    async ({pageNum, pagingSize}: {pageNum: number, pagingSize: number}) => {
        // firebase에서 userToken받아오기
        let userToken = await auth.currentUser?.getIdToken();    

        const fetchUrl = '/user/profile/achievements';

        const options: FetchOptions = {
            method: 'GET',
            userToken: userToken || '', // userToken이 없을 시(비로그인) 빈 문자열 전달
            data: {
                pageNum: pageNum,
                pagingSize: pagingSize,
            },
        };

        return await postURI(fetchUrl, options);
    });