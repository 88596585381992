import { createAsyncThunk } from "@reduxjs/toolkit";
import { auth } from "firebase-config";
import { FetchOptions, fetchQuizQuestions, postURI } from "./baseFunction/ApiService";
import { UserStudySettingProp } from "model/UserStudySetting";

/**
 * 학습목표 전송
 */
export const postStudySettings = createAsyncThunk(
    'v2/goal/study_settings/',
    async (studySettings: UserStudySettingProp) => {
      // firebase에서 userToken받아오기
      const userToken = await auth.currentUser?.getIdToken();
  
      // 로그인이 되어있지 않은 상태라면 return
      if (userToken === undefined) return;
  
      const fetchUrl = '/v2/goal/study_settings/';
  
      const options: FetchOptions = {
        method: 'POST',
        userToken: userToken || '', // userToken이 없을 시(비로그인) 빈 문자열 전달
        data: studySettings,
      };
  
      await fetchQuizQuestions(fetchUrl, options);
  });