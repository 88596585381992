import { createSlice } from '@reduxjs/toolkit';


interface Modal {
    modalType: string;  // 모달 타입
    isOpen: boolean;    // 열려있는 상태인지
    data?: string;      // 모달에 넘겨줄 데이터
}

interface RootState {
    modal: Modal;
}

const initialState:Modal = {
  modalType: "",    // 미리 설정한 모달 타입
  isOpen: false,    // 모달 open여부
  data: "",         // 모달에 넘겨줄 데이터
};

export const modal = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        // 모달 열기
        openModal: (state, actions) => {
            const { modalType, data } = actions.payload;

            state.modalType = modalType;
            state.isOpen = true;
            state.data = data;
        },
        // 모달 닫기
        closeModal: (state) => {
            state.isOpen = false;
        },
    },
});

export const { openModal, closeModal } = modal.actions;
export const selectModal = (state:RootState) => state.modal;

export default modal.reducer;