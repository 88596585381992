import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { TopicList, topic } from 'model/topicListModel';

const initialState: TopicList = {
    drawerOpened: false,    // drawer open여부
    topicList: []           // 관련자료 리스트
}

// 관련자료 주제 리스트
export const topicList = createSlice({
    name: 'topicList',
    initialState,
    reducers: {
        // Drawer 닫기
        handleDrawerOpen(state, action: PayloadAction<boolean>) {
            state.drawerOpened = false;
        },
        // 관련자료 클릭 시 topicList에 추가
        getTopic(state, action: PayloadAction<topic>) {
            state.drawerOpened = true;
            
            // 최근 자료는 3개까지만 표시.
            if (state.topicList.length > 2) {
                state.topicList.shift();
            }
            
            // 중복 제거 및 요소 추가
            state.topicList = state.topicList.filter( v => v.id !== action.payload.id);
            state.topicList = [...state.topicList, action.payload];
        },
    },
});

export const { getTopic, handleDrawerOpen } = topicList.actions;

export default topicList.reducer;