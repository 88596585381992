import * as React from 'react';
import { Divider, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { closeModal } from 'redux/modules/modal';

import { auth } from "firebase-config";
import { persistor } from "redux/store/store";
import { useAppDispatch } from 'hooks/hooks';


const LogoutModal: React.FC = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    // 닫기버튼 눌렀을 때
    const handleClose = () => {
        dispatch(closeModal());
    };

    // 로그아웃
    const handleLogout = async () => {
        try{
            // firebase 로그아웃
            await auth.signOut();

            // 저장된 정보 초기화
            await persistor.purge();

            // 모달 닫고 홈으로 이동
            handleClose();
            navigate('/');
        }catch(err){
            console.log(err);
        }
    }

    return (
        <>
            {/* 서비스 간단 소개 */}
            <Typography variant="h6" pt={2} pb={1}>로그아웃</Typography>
            <Divider />
            <Typography variant="body2" pt={1.5} pb={4} whiteSpace={"pre-wrap"}>
                다시 로그인 하시면 저장된 정보가 복구됩니다.<br/>
                로그아웃 하시겠어요?
            </Typography>

            {/* 로그아웃 버튼 */}
            <Button
                variant="contained"
                disableElevation
                fullWidth
                sx={{ background: "#ce1f1f", borderRadius: "12px", padding: "12px", marginBottom: "12px"}}
            >
                <label
                    style={{ width: "100%", fontSize: "14px" }}
                    onClick={handleLogout}
                >
                    로그아웃
                </label>
            </Button>
            <Button
                variant="outlined"
                fullWidth
                disableElevation 
                sx={{ borderRadius: "12px", padding: "12px" }}
                onClick={handleClose}
            >
                <label style={{ width: "100%" }}>돌아가기</label>
            </Button>
        </>
    );
};

export default LogoutModal;