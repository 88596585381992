/**
 * UTC 시간이기 때문에 한국과 9시간 차이남. 아래와 같이 해결
 * const d = new Date();
 * => new Date(d.getTime() - (d.getTimezoneOffset() * 60000)).toISOString()
 * => 현재 날짜 & 시간 yyyy-mm-dd hh:mm:ss 형식으로 포맷
 */

const timeFormat = (date: Date, isString: boolean): Date => {
    const dateTmp = isString ? new Date(date) : date;

    // UTC 시간 계산
    const utc = dateTmp.getTime() + (dateTmp.getTimezoneOffset() * 60 * 1000);

    // UTC to KST (UTC + 9시간)
    const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
    const kr_curr = new Date(utc + (KR_TIME_DIFF));

    return kr_curr;
    //   .toISOString()
    //   .replace("T", " ")
    //   .replace(/\..*/, "");
};

export default timeFormat;