import React, { useState }  from "react";
import LoginView from "./LoginView";

import { useNavigate } from "react-router-dom";
import { signInUser } from "redux/modules/userAuth";
import { useAppDispatch } from "hooks/hooks";
import { getUserStat } from "redux/modules/API/getUserStat";


interface LoginCredentials {
    email: string;      // 유저가 입력한 이메일
    password: string;   // 유저가 입력한 비밀번호
};

const LoginContainer:React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errMsg, setErrMsg] = useState('');

    // email 변수에 대한 변경사항을 업데이트
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    // 폼 제출 시
    const handleSubmit = async(event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const credentials: LoginCredentials = {email, password};
            // 사용자 데이터 설정
            await dispatch(signInUser(credentials)).unwrap();

            // 서버에서 사용자 데이터 받아온 후 저장하기
            await dispatch(getUserStat());

            // 홈으로 이동
            navigate('/');
        } catch (error: any) {
            // 로그인 에러 핸들링
            if (error.errorCode === 'auth/invalid-email'){
                setErrMsg('잘못된 이메일입니다.');
            } else if (error.errorCode === 'auth/wrong-password'){
                setErrMsg('잘못된 비밀번호입니다.');
            } else {
                setErrMsg('잘못된 접근입니다.');
            }
        }
    };

    return(
        <LoginView 
            errMsg = {errMsg}
            email = {email}
            password = {password}
            setPassword = {setPassword}
            handleSubmit = {handleSubmit}
            handleEmailChange = {handleEmailChange}
        />
    );
};

export default LoginContainer;