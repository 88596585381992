import { auth, EmailAuthProvider, googleAuthProvider, reauthenticateWithPopup, reauthenticateWithCredential } from 'firebase-config';


export const reauthenticate = async (password: string): Promise<string> => {
    // 현재 로그인된 사용자 가져오기
    const currentUser = auth.currentUser;
    if (!currentUser) return '현재 사용자가 존재하지 않습니다.';

    // userAuthType 초기화
    let userAuthType: string | null = null;

    const providerData = currentUser.providerData;
    providerData?.forEach((userInfo) => {
        if (userInfo.providerId === 'password') {
            // 이메일로그인일 시 userAuthType에 password저장
            userAuthType = 'password';
        } else if (userInfo.providerId === 'google.com') {
            // 구글로그인일 시 userAuthType에 google저장
            userAuthType = 'google';
        }
    });

    try {
        if (userAuthType === 'google') {
            // 구글로그인일 시
            await reauthenticateWithPopup(currentUser, googleAuthProvider)
            return 'success';

        } else if (userAuthType === 'password') {
            // 이메일로그인일 시
            const email = currentUser.email;
            if (!email) return '이메일이 존재하지 않습니다.';

            const emailCredentials = EmailAuthProvider.credential(email, password);

            await reauthenticateWithCredential(currentUser, emailCredentials)
            return 'success';

        } else {
            // 위 타입에 해당 없을 시
            return '잘못된 접근입니다.';
        }
    } catch (error:any) {
        if (error.code === 'auth/wrong-password') {
            return '잘못된 비밀번호입니다.';
        } else if (error.code === 'auth/too-many-requests') {
            return '로그인 시도가 잦습니다. 잠시 후에 다시 시도하세요.'
        } else {
            return '잘못된 접근입니다.';
        }
    }

};