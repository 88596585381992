import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AchievementType, UserdataType } from 'model/UserDataModel';
import achievementData from "../../mockData/achievements.json";


const initialState: UserdataType = {
    achievements: [],  // 사용자가 달성한 업적의 전체 정보
};

// 관련자료 주제 리스트
export const userData = createSlice({
    name: 'userData',
    initialState,
    reducers: {
        
        /** 
         * 새로 추가된 업적을 user업적에 추가하기.
         * 백에서는 업적 id만 반환하기 때문에 achievementData서 전체 데이터를 가져옴
         * 업적들을 리스트로 변환
         */
        setAchievementData(state, action: PayloadAction<string[]>) {
            // 각 업적ID에 해당하는 achievementData의 데이터들을 뽑아내기
            state.achievements = action.payload.length > 0 
                                    ? achievementData.achievement.filter(item => action.payload.includes(item.achievement_id))
                                    : [] ;
        },
        /** 획득 못한 업적들을 리스트로 변환 */
        setEmptyAchievementData(state, action: PayloadAction<string[]>){
            // let result = [...achievementData.achievment]; // id에 매칭해서 아닌 것들만 남기기
            // userAchievementIdList.map((achievementId: string) => {
            //     const idx = result.findIndex((item) => {
            //         return item.achievement_id === achievementId;
            //     });
            //     result.splice(idx, 1);
            // });
            // return result;
        }
    },
});

export const { setAchievementData, setEmptyAchievementData } = userData.actions;

export default userData.reducer;