import { Card, CardContent, Divider, Grid, Pagination, Typography } from "@mui/material";
import ButtonWithBackground from "components/ButtonWithBackground/ButtonWithBackground";
import CustomMarkdown from "components/CustomMarkdown/CustomMarkdown";
import QuizAppBar from "components/QuizAppBar/QuizAppBar";
import QuizArticle from "components/QuizArticle/QuizArticle";
import Title from "components/Title/Title";
import { QuizModel } from "model/QuizModel";
import { ReferenceModel, SearchResultInterface, TopicModel } from "model/searchDetailModel";
import React, { useEffect, useState } from "react";

interface SearchDetailContainerProps {
    loading: boolean;
    searchKeyword: string | undefined;
    contentType: string;
    searchResult: SearchResultInterface<ReferenceModel[] | QuizModel[] | TopicModel[]>;
    currentPage: number;
    handlePageChange: (event: React.ChangeEvent<unknown>, value: number) => void;
    goToSearchPage: (buttonText: string) => void;
};

const SearchDetailView: React.FC<SearchDetailContainerProps> = ({ loading, contentType, searchKeyword, searchResult, currentPage, handlePageChange, goToSearchPage }) => {
    // content type에 따라 다른 컴포넌트를 나타내기 위한 변수
    const [content, setContent] = useState<React.ReactNode | null>(null);

    useEffect(() => {
        // 로딩 중일 때
        if (loading) {
            setContent(<Typography variant="body2" color={'#909090'} sx={{ pt: 2, pb: 4 }}>검색 중입니다...</Typography>);
            return;
        }

        // 검색 결과가 없을 때
        if (searchResult.result.length === 0) {
            setContent(<Typography variant="body2" color={'#909090'} sx={{ pt: 2, pb: 4 }}>검색 결과가 없습니다.</Typography>);
            return;
        }

        // content type에 따라 다른 컴포넌트를 나타냄
        if (contentType === "문제") {
            // contentType이 문제일 때
            const quizCards = searchResult.result.map((quiz: any, index: number) => (
                <Card key={index} sx={{ pb: '14px', mb: 2 }} onClick={() => { }}>
                    <QuizArticle quizItems={quiz} isClickable={true} currentUrl={`/search/quiz/${searchKeyword}`}/>
                </Card>
            ));
            setContent(quizCards);
        } else if (contentType === "관련주제") {
            // contentType이 관련주제일 때
            const buttonsContent = searchResult.result.map((result: any) => result.topicName);
            const topicButtons = <ButtonWithBackground text={buttonsContent} onClick={goToSearchPage} />;

            setContent(topicButtons);
        } else if (contentType === "참고자료") {
            // contentType이 참고자료일 때
            const referenceCards = searchResult.result.map((reference: any, index: number) => (
                <Card key={index} sx={{ mb: 2 }}>
                    <CardContent>
                        <CustomMarkdown content={reference.referenceData} />
                    </CardContent>
                </Card>
            ));
            setContent(referenceCards);
        }
    }, [contentType, searchResult]);



    return (
        <div>
            <QuizAppBar navType="탐색" navKeyword={searchKeyword} />
            <Grid
                pt={4}
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ width: '100%' }}
            >
                <Title title="검색 결과" description={`"${searchKeyword}" 검색 결과입니다.`} />
                <Divider sx={{ width: '100%' }} />

                <Title title={contentType} count={searchResult.result.length} />
                {content}

                <Pagination count={searchResult.totalPages} page={currentPage} onChange={handlePageChange} sx={{ m: '16px 0' }} />
            </Grid>
        </div>
    );
};

export default SearchDetailView;