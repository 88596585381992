import { BookmarkBorder, HighlightOff, History } from "@mui/icons-material";
import NavigateNext from "@mui/icons-material/NavigateNext";
import { Avatar, Box, CircularProgress, Grid, IconButton, SvgIconProps, Typography, circularProgressClasses } from "@mui/material";
import Achievements from "components/Achievements/Achievements";
import NavBar from "components/NavBar";
import Title from "components/Title/Title";
import TopAppBar from "components/TopAppBar/TopAppBar";
import React from "react";

interface MYViewProps {
    count: number;                          // 현재까지의 학습문제 수
    dailyGoal: number;                      // 일일 목표 문제 수
    handleNavigate: (path: string) => void; // 페이지 이동 핸들러
}

interface GridItemProps {
    title: string;          // 그리드 아이템의 제목
    action?: () => void;    // 그리드 아이템의 액션
    item: React.ReactNode;  // 그리드 아이템에 들어갈 컴포넌트
}

// 그리드 아이템 컴포넌트
const GridItem: React.FC<GridItemProps> = ({ title, action, item }) => (
    <Grid item sx={{ 
            bgcolor: 'white', 
            width: '100%', 
            marginTop: '16px', 
            paddingBottom: '28px'
            }}>
        <Title title={title} action={action} setMaxWidth={false} />
        {item}
    </Grid>
);

// 버튼 아이템 컴포넌트
const ButtonItem: React.FC<{ Icon: React.ElementType<SvgIconProps>, title: string, action: () => void }> = ({ Icon, title, action }) => (
    <Grid>
        <Box 
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}
            ml={2}
            mb={2}
            onClick={action}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar sx={{ bgcolor: 'grey.200' }}>
                    <Icon sx={{ color: '#7c7c7c' }}/>
                </Avatar>
                <Typography variant="body1" component="div" sx={{ ml: 2 }}>
                    {title}
                </Typography>
            </Box>
            <IconButton
                sx={{ color: '#909094', mr: 2 }}
            >
                <NavigateNext />
            </IconButton>
        </Box>
    </Grid>
);

/**
 * 사용자 분석 및 통계 페이지
 * @returns MY 페이지
 */
const MYView: React.FC<MYViewProps> = ({ count, dailyGoal, handleNavigate }) => {
    const currentCount = count/dailyGoal;
    return (
        <>
            <TopAppBar />
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1.2}
                sx={{ paddingTop: '56px', paddingBottom: '56px' }}
            >
                
                {/* 일일목표 달성도 */}
                <Title title="일일목표 달성도" setMaxWidth={false}/>
                <Box sx={{ position: 'relative', display: 'inline-flex', mb: 3 }}>
                <CircularProgress
                    variant="determinate"
                    sx={{ color: '#e6e6ee'}}
                    size={200}
                    value={100}
                />
                    <CircularProgress 
                        variant="determinate" 
                        value={currentCount>1 ? 100: currentCount*100 } 
                        sx={{ 
                            position: 'absolute',
                            left: 0,
                            borderRadius: '50%',
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                              },
                            color: '#4171d8'
                        }}
                        size={200}
                    />
                    <Box
                        sx={{
                            top: '12px',
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Typography variant="h4" component="div" sx={{ color: '#4171d8' }}>
                            {Math.ceil(currentCount*100)}%
                        </Typography>
                        <Typography variant="body2" component="div" sx={{ textAlign: 'center', color: '#a8a8a8' }}>
                            {count} / {dailyGoal}
                        </Typography>
                    </Box>
                </Box>


                {/* 모은 업적들 */}
                <GridItem title="업적" action={() => {handleNavigate('achievements')}} item={<Achievements />} />

                {/* 문제 모아보기 */}
                <GridItem title="MY Quiz" item={
                    <>
                        <ButtonItem Icon={History} title="풀었던 문제" action={() => { handleNavigate('played') }} />
                        <ButtonItem Icon={HighlightOff} title="틀린 문제" action={() => { handleNavigate('mistake') }} />
                    </>
                }/>

            </Grid>
            <NavBar />

        </>
    );
};

export default MYView;