import React from "react";
import IndexTypoView from "./IndexTypoView";

import { useAppSelector, useAppDispatch } from "hooks/hooks";
import { getSelection } from "redux/modules/cardList";
import { QuizModel } from "model/QuizModel";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "firebase-config";
import { openModal } from "redux/modules/modal";

interface IndexTypoProps {
  quizContent: QuizModel; //현재 퀴즈
  disableClick: boolean;  // 선지를 클릭했을 때 backgroundColor를 변경시킬지 (현재 문제에서 클릭한 선지가 다음 문제의 선지에 반영되지 않게 하기 위함)
};

/*
* 문제 선지 컴포넌트
* 각 선지의 Index와 내용을 표시
*/
const IndexTypoContainer: React.FC<IndexTypoProps> = ({ quizContent, disableClick }) => {
  const dispatch = useAppDispatch();

  // 선택한 번호(다항식)
  const selectedOption = useAppSelector(state => state.cardList.selectedIndex);
  // quiz화면인지?
  const isQuiz = useAppSelector(state => state.isQuiz.quiz);

  // 로그인 유도 모달 띄울 때 필요한 변수
  const quizCount: number = useAppSelector(state => state.quizCounter.count);
  const dailyUnitStudy: number = useAppSelector(state => state.attendance.dailyUnitStudy);


  // 선택한 지문 저장
  const handleOptionClick = (option: number) => {
    dispatch(getSelection(option));

    // 일일학습 1번 이상 푼 다음 첫 문제를 풀었을 때 로그인 유도 모달 띄우기
    onAuthStateChanged(auth, async (user) => {
      // 로그인 정보가 없을 시
      if (!user && quizCount === 0 && dailyUnitStudy > 0) {
        dispatch(
          openModal({
            modalType: "LoginModal",
          })
        );
      }
    });
  };

  return (
    <IndexTypoView
      currentQuiz={quizContent}
      selectedOption={selectedOption}
      handleOptionClick={handleOptionClick}
      isQuiz={isQuiz}
      disableClick={disableClick}
    />
  );
};

export default IndexTypoContainer;