import React from "react";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { closeModal, selectModal } from "redux/modules/modal";
import { Modal, Box } from "@mui/material";
import LoginModal from "../LoginModal";
import LogoutModal from "../LogoutModal";
import QuitModal from "../QuitModal";
import AnalyzeModal from "../AnalyzeModal";
import GoHomeModal from "../GoHomeModal";
import AttendanceModal from "../AttendanceModal";
import ErrorReportModal from "../ErrorReportModal";
import AchievementsModal from "../AchievementsModal";

// 모달 타입 네이밍
const MODAL_TYPES = {
    LoginModal: "LoginModal",
    LogoutModal: "LogoutModal",
    QuitModal: "QuitModal",
    AnalyzeModal: "AnalyzeModal",
    GoHomeModal: "GoHomeModal",
    AttendanceModal: "AttendanceModal",
    ErrorReportModal: "ErrorReportModal",
    AchievementsModal: "AchievementsModal",
};

// 모달 컴포넌트 연결
const MODAL_COMPONENTS = [
    {
        type: MODAL_TYPES.LoginModal,
        component: LoginModal,
    },{
        type: MODAL_TYPES.LogoutModal,
        component: LogoutModal,
    },{
        type: MODAL_TYPES.QuitModal,
        component: QuitModal,
    },{
        type: MODAL_TYPES.AnalyzeModal,
        component: AnalyzeModal,
    },{
        type: MODAL_TYPES.GoHomeModal,
        component: GoHomeModal,
    },{
        type: MODAL_TYPES.AttendanceModal,
        component: AttendanceModal,
    },{
        type: MODAL_TYPES.ErrorReportModal,
        component: ErrorReportModal,
    },{
        type: MODAL_TYPES.AchievementsModal,
        component: AchievementsModal,
    },
];

// 모달 style
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    maxWidth: 512,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '24px',
};

const GlobalModal: React.FC = () => {
    // modal type을 string 형태로 받기
    const { modalType, isOpen, data } = useAppSelector(selectModal);
    const dispatch = useAppDispatch();

    // 해당 타입의 모달 return
    const findModal = MODAL_COMPONENTS.find((modal) => {
        return modal.type === modalType;
    });

    const renderModal = () => {
        if (findModal?.component) {
            const ModalComponent = findModal.component as unknown as React.ComponentType<{ data?: string }>;
            return <ModalComponent data={data} />;
        }
    };

    return (
        <Modal
            open={isOpen}
            onClose={() => dispatch(closeModal())}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Box sx={style}>
                {renderModal()}
            </Box>
        </Modal>
    );
};

export default GlobalModal;