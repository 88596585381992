import React from "react";
import MarkDown from "react-markdown";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { TextStyle, ATagStyle, Contents } from "model/CustomMarkdownModel";
import { useAppSelector, useAppDispatch } from "hooks/hooks";
import { getTopic } from "redux/modules/topicList";
import { topic } from "model/topicListModel";


// 마크다운 텍스트 스타일
const textStyle: TextStyle = {
    color: '#1A1C1E',
    fontSize: '14px',
    fontFamily: ['Noto Sans KR', 'Noto Sans', 'Roboto'].join(','),
    fontWeight: "500",
    margin: "4px 0 4px 0",
    display: "inline"
};

const innerTextStyle: TextStyle = Object.assign({}, textStyle, { margin: "0" });

// 관련자료 링크 스타일
const aTagStyle: ATagStyle = {
    background: "#F5D9FF",
    color: "#1A1C1E",
    textDecoration: "none",
    borderRadius: "4px",
    padding: "0 2px 0 4px",
    margin: "0 2px",
    alignItems: "center",
    fontSize: "12px"

}

/*
* 마크다운 컴포넌트
*/
const CustomMarkdown: React.FC<Contents> = ({ content }) => {
    const dispatch = useAppDispatch();
    const isQuiz = useAppSelector(state => state.isQuiz.quiz);

    // 관련자료 태그 눌렀을 때 연결
    const handleNav = (linkText: string | undefined, linkContent: string | undefined) => {
        // '/' 뒤의 숫자 추출
        if (linkText && linkContent) {
            const match = linkText.match(/\/([^/]+)$/);
            if (match) {
                const topicNum = parseInt(match[1]);
                
                // topicNum이 NaN이 아닌 경우에만 dispatch 실행
                if (!isNaN(topicNum)) {
                    const topicData:topic = {
                        id: topicNum,
                        content: linkContent
                    }
                    
                    dispatch(getTopic(topicData));
                }
            }
        }
    };

    return (
        <MarkDown components={{
            img: ({ ...node }) => <img style={{ maxWidth: '100%' }} {...node} />,
            p: ({ ...node }) => <span style={textStyle} {...node} />,

            // 해설일 때만 관련자료 태그 노출
            a: ({ href, children, ...node }) => (
                <>
                    {isQuiz ? (
                        <span style={innerTextStyle}>{children}</span>
                    ) : (
                        <a style={aTagStyle} onClick={() => handleNav(href, children?.toString())}>
                            {children}
                            <NavigateNextIcon sx={{ fontSize: "8.8px" }} />
                        </a>
                    )}
                </>
            ),
        }}>
            {content}
        </MarkDown>
    );
};

export default CustomMarkdown;
