import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { QuizModel } from 'model/QuizModel';
import { searchQuiz } from './API/getSearchResults';

interface searchDataProps {
    currentSearchedQuiz: number;    // 현재 페이지 번호
    searchQuizList: QuizModel[];     // 퀴즈 리스트
    loading: "idle" | "succeeded" | "failed" | "pending";  // 퀴즈 로딩 상태
    searchKeyword: string; // 검색 키워드
}

const initialState:searchDataProps  = {
    currentSearchedQuiz: 1,     // drawer open여부
    searchQuizList: [],  // 퀴즈 리스트
    loading: "idle",  // 퀴즈 로딩 상태
    searchKeyword: '' // 검색 키워드
};

// 관련자료 주제 리스트
export const searchData = createSlice({
    name: 'searchData',
    initialState,
    reducers: {
        // 퀴즈 리스트 비우기
        resetSearchQuizList(state) {
            state.searchQuizList = [];
        },
        // 현재 퀴즈 추가
        addSearchQuiz(state, action: PayloadAction<QuizModel>) {
            // undefined 제거
            state.searchQuizList = state.searchQuizList.filter(quiz => quiz !== undefined);

            // 같은 quizId가 없을 때 추가
            if (state.searchQuizList.findIndex(quiz => quiz.quizId === action.payload.quizId) === -1) {
                state.searchQuizList.push(action.payload);
            }
        },
        // 문제 넘기기
        passSearchQuiz(state) {
            state.searchQuizList.shift();
        },
        // 현재 문제 번호 1 증가
        increaseCurrentSearchedQuiz(state) {
            state.currentSearchedQuiz += 1;
        },
        // 검색 키워드를 바꾸고 검색된 문제들 중 첫 번째 문제로 초기화
        setSearchKeyword(state, action){
            state.searchKeyword = action.payload;
            state.currentSearchedQuiz = 1;
        }
        
    },
    extraReducers: (builder) => {
        builder
            // 문제 가져오기 성공
            .addCase(searchQuiz.fulfilled, (state) => {
                state.loading = 'succeeded';
            })
            .addCase(searchQuiz.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(searchQuiz.rejected, (state) => {
                state.loading = 'failed';
            })
        },
});

export const { resetSearchQuizList, addSearchQuiz, passSearchQuiz, increaseCurrentSearchedQuiz, setSearchKeyword } = searchData.actions;

export default searchData.reducer;