// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import 'firebase/firestore';
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  EmailAuthProvider,
  GoogleAuthProvider,
  deleteUser,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  updateProfile,
  reauthenticateWithPopup,
  reauthenticateWithCredential,
} from "firebase/auth";

// project config
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Authentication SDK 추가 및 초기화
const auth = getAuth(app);

// google analytics
const analytics = getAnalytics(app);

/** google auth provider */
const googleAuthProvider = new GoogleAuthProvider();



export {
  auth,
  analytics,
  logEvent,
  signInWithEmailAndPassword,
  signInWithPopup,
  EmailAuthProvider,
  googleAuthProvider,
  deleteUser,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  updateProfile,
  reauthenticateWithPopup,
  reauthenticateWithCredential,
};