import React from "react";
import { purposeType, startTypo } from "./PurposeSettingContainer";
import { Button, List, Typography, ListItemButton, Stepper, Step, StepLabel, Grid, Chip, Card, IconButton, Box } from '@mui/material';
import { NavigateBefore, NavigateNext } from "@mui/icons-material";

interface PurposeSettingProps {
    currentIndex: number;                           // 현재 슬라이드의 index
    handleClickStart: () => void;                   // 마지막 슬라이드에서 서비스 홈으로 이동
    handleClickNext: () => Promise<void>;           // 슬라이드 넘기기
    handleOptionClick: (pageIndex: number, option: number, item?: string) => void;    // 선택한 인덱스 저장
    handleChipClick: (level: string, index: number) => void;       // 선택한 칩 클릭 시 저장
    selectedOption: number[];                       // 고른 번호
    isSelected: boolean;
    purpose: purposeType;  // 선택한 목표
    dailyGoal: number;     // 일일목표
    handleInputChange: (newValue: number) => void;  // 일일목표 직접 입력 시
};

// 상단 typography 높이
const TYPO_TOP = '24px';

// 하단 button 높이
const BUTTONS_HEIGHT = '64px';


const PurposeSettingView: React.FC<PurposeSettingProps> = ({
    currentIndex,
    handleClickStart,
    handleClickNext,
    handleOptionClick,
    handleChipClick,
    selectedOption,
    isSelected,
    purpose,
    dailyGoal,
    handleInputChange
}) => {
    const normalItemButtonsStyle = {
        border: "1px solid #d8d8d8",
        borderRadius: "12px",
        height: "64px",
        paddingX: "24px",
        justifyContent: "space-between",
        fontSize: "18px",
    };


    // 현 페이지 상태에 따라 item들의 padding과 열 갯수를 결정
    const itemPadding = currentIndex === 1 ? 1 : 0;
    const itemXS = currentIndex === 1 ? 6 : 12;


    // 렌더링 할 선택지 컴포넌트 ----------------------------------------------

    // TopicLevel 단계의 선택지
    const TopicLevel: React.FC = () => {
        // topic 페이지에서 선택한 항목을 가져옴
        const contentToChoose = purpose.topic;

        return (
            <Grid container sx={{ width: "100%", maxWidth: "512px" }}>
                {startTypo[currentIndex] && contentToChoose.map((item: string, index: number) => (
                    <Grid key={index} item xs={itemXS} px={itemPadding}>
                        {/* topicLevel 페이지일 경우에는 선택지 옆에 level 선택 버튼을 추가 */}
                        <Card
                            variant="outlined"
                            key={index}
                            sx={{ p: 1.5, mb: 1.5, display: 'flex', justifyContent: 'space-between' }}
                        >
                            {item}
                            <span>
                                {startTypo[currentIndex].purpose === 'topicLevel' &&
                                    ["입문", "발전", "전문"].map((level, chipIndex) => (
                                        <Chip
                                            key={level} label={level}
                                            clickable sx={{ ml: '8px' }}
                                            color={purpose.topicGoals[index].goal === level ? 'primary' : 'default'} // 선택된 Chip은 primary 색상으로 표시
                                            onClick={() => handleChipClick(level, index)}
                                        />
                                    ))
                                }
                            </span>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        )
    };

    // dailyGoal 단계의 선택지
    const DailyGoal: React.FC = () => {
        return (
            <Grid container sx={{ width: "100%", maxWidth: "512px", paddingTop: "80px"}} justifyContent="center" alignItems="center" >
                <IconButton
                    onClick={() => handleInputChange(dailyGoal - 5)}
                    disabled={dailyGoal === 5}
                    size="large"
                >
                    <NavigateBefore fontSize="inherit" />
                </IconButton>
                <Typography variant="h3" width={'88px'} textAlign={'center'}>{dailyGoal}</Typography>
                <IconButton
                    onClick={() => handleInputChange(dailyGoal + 5)}
                    disabled={dailyGoal === 50}
                    size="large"
                >
                    <NavigateNext fontSize="inherit" />
                </IconButton>
            </Grid>
        );
    };

    // items 중 하나를 고르는 기본 선택지
    const ItemButtons: React.FC = () => {
        return (
            <Grid container sx={{ width: "100%", maxWidth: "512px" }}>
                {startTypo[currentIndex] && startTypo[currentIndex].items.map((item: string, index: number) => (
                    <Grid key={index} item xs={itemXS} px={itemPadding}>
                        <List>
                            <ListItemButton
                                key={index}
                                onClick={() => handleOptionClick(currentIndex, index)}
                                selected={selectedOption.includes(index)}
                                sx={normalItemButtonsStyle}
                            >
                                {item}
                            </ListItemButton>
                        </List>
                    </Grid>
                ))}
            </Grid>
        )
    };

    // 렌더링 할 선택지 고르기
    const renderComponent = () => {
        switch (startTypo[currentIndex]?.purpose) {
            case 'dailyGoal':
                return <DailyGoal />;
            case 'topicLevel':
                return <TopicLevel />;
            default:
                return <ItemButtons />;
        }
    };


    return (
        <div>
            <div style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                padding: `${TYPO_TOP} 16px`,
                gap: "12px",
            }}>

                <div
                    style={{
                        width: '100%',
                        maxWidth: '512px',
                        marginBottom: '24px',
                        alignItems: 'center',
                    }}
                >
                    {/* 학습목표 설정 step 현황 확인 */}
                    <Stepper activeStep={currentIndex} alternativeLabel
                        sx={{ '& .MuiStepLabel-label.MuiStepLabel-alternativeLabel': { marginTop: '6px' }, paddingBottom: '24px' }}
                    >
                        {startTypo.slice(0, -1).map((typoItem) => (
                            <Step key={typoItem.step}>
                                <StepLabel
                                >{typoItem.step}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    {/* 현 step 설명 */}
                    <Typography variant="h5" mb={2} mt={4}>
                        {startTypo[currentIndex].title}
                    </Typography>
                    <Typography variant="body2">
                        {startTypo[currentIndex].description[0]}
                    </Typography>
                    <Typography variant="body2">
                        {startTypo[currentIndex].description[1]}
                    </Typography>
                </div>

                <Grid container sx={{ width: "100%", maxWidth: "512px", marginBottom: BUTTONS_HEIGHT }}>

                    {/* 선택지 */}
                    {renderComponent()}


                    {/* 다음, 건너뛰기 버튼 */}
                </Grid>
                <Box display="flex" flexDirection="column" justifyContent="flex-end" position="fixed" bottom={0} width="100%" maxWidth="512px">
                    {currentIndex === startTypo.length - 1 ?
                        <Button
                            variant="contained"
                            onClick={handleClickStart}
                            sx={{ width: '100%', height: BUTTONS_HEIGHT, borderRadius: 0}}
                        >
                            시작하기
                        </Button>
                        :
                        <Button
                            variant="contained"
                            onClick={handleClickNext}
                            sx={{ width: '100%', height: BUTTONS_HEIGHT, borderRadius: 0 }}
                            disabled={!isSelected}
                        >
                            다음
                        </Button>
                    }
                </Box>


            </div>
        </div>
    )
};

export default PurposeSettingView;