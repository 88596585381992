import React from "react";
import ExplanationPageView from "./ExplanationPageView";
import { useAppSelector } from "hooks/hooks";
import { QuizModel } from 'model/QuizModel';
import QuizAppBar from "components/QuizAppBar/QuizAppBar";
import { Grid } from "@mui/material";
import NoQuiz from "components/NoQuiz/NoQuiz";
import QuizNav from "components/QuizNav/QuizNav";
import { useParams } from "react-router-dom";

const ExplanationPageContainer: React.FC = () => {
    const TypoOX: (string | undefined) = useAppSelector((state) => state.cardList.typo);
    let normalDescription: (string | undefined) = useAppSelector((state) => state.cardList.quizList[0]?.commentaryText);
    let normalQuizList: QuizModel[] = useAppSelector(state => state.cardList.quizList);

    const { keyword, id } = useParams();
    const quizSearchList = useAppSelector(state => state.searchData.searchQuizList);
    const searchDescription = useAppSelector(state => state.searchData.searchQuizList[0]?.commentaryText)

    let description = normalDescription;
    let quizList = normalQuizList;

    if (keyword) {
        quizList = quizSearchList;
        description = searchDescription;
    }



    // 해설이 제공되지 않을 시 간단한 답만 제공
    if (description === "") {
        const answer = quizList[0].examples.filter(v => v.isAnswer === true);
        description = answer[0] ? `문제의 옳은 답은 "${answer[0].exampleText}" 입니다.` : "정답이 존재하지 않습니다. 문제 신고로 문제를 제보해주세요.";
    }

    // 문제를 정상적으로 불러오지 못했을 때 퀴즈를 새로 불러올 수 있는 컴포넌트 출력
    if (quizList.length < 1) {
        return (
            <div style={{ overflow: "hidden"}}>
            <QuizAppBar navType="종료하기" navKeyword="종료하기" />
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ padding: '56px 0 60px 0' }}
            >
                <div
                    style={{
                        height: '20px',
                        position: "relative",
                        transform: "translateZ(0)",
                    }}
                >
                    <NoQuiz/>
                </div>
            </Grid>
            <QuizNav/>
        </div>
        );
    };

    return (
        <ExplanationPageView
            TypoOX={TypoOX}
            description={description}
            curQuiz={quizList[0]}
            keyword={keyword}
        />
    );
};

export default ExplanationPageContainer;