import { createTheme } from "@mui/material";


export const theme = createTheme({
    components: {
        MuiPaper: {
            styleOverrides: {
                outlined: {
                    borderRadius: '12px',
                },
                elevation1: {
                    borderRadius: '24px',
                    maxWidth: '512px',
                    width: `calc(100vw - 32px)`,
                    boxShadow: '0px 4px 12px 8px rgba(238, 238, 242, 0.40)'
                },
                elevation5: {
                    borderRadius: '24px',
                    maxWidth: '512px',
                    width: `calc(100vw - 32px)`,
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    '&:last-child': {
                        paddingBottom: '16px',
                    }
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#FFFFFF',
                    color: '#1A1C1E',
                    boxShadow: 'none',
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    height: "24px",
                    fontSize: "11px",
                    borderRadius: "8px",
                    background: "#F3F3F7",
                    "&&:hover": {
                        backgroundColor: "#F3F3F7",
                    }
                },
                colorPrimary: {
                    backgroundColor: "#00639B",
                    "&&:hover": {
                        backgroundColor: "#00639B",
                    }
                }
            }
        },
        MuiCardHeader: {
            styleOverrides: {
                action: {
                    margin: "auto",
                    display: "flex",
                },    
                title: {
                    margin: "4px 2px",
                    fontSize: "12px",
                },
                subheader: {
                    margin: "0 0 4px 2px",
                    fontSize: "12px",
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: "12px",
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: "12px",
                }
            }
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    background: "transparent",
                    boxShadow: "none",
                }
            }
        }
    },
    palette: {
        primary: {
            main: "#00639B",
        },
        secondary: {
            main:"#0062A1",
        },
        background: {
            default: "#F3F3F7",
        },
    },
    typography: {
        fontFamily: ['Noto Sans KR', 'Noto Sans', 'Roboto'].join(','),
        fontWeightRegular: 500,
        subtitle1: {
            margin:'36px 0 12px 4px',
            fontSize: '16px',
            width:'90vw', 
            maxWidth: '512px',
        },
        body1: {
            fontSize: '16px',
        },
        body2: {
            fontSize: '14px',
        }
    },
});