import { createSlice } from '@reduxjs/toolkit';
import timeFormat from 'utils/timeFormat';
import { PURGE } from 'redux-persist';

interface attendanceProps {
    attendanceTimeStamp: Date;  // 일일 첫 접속 시간
    todaysFirstAccess: boolean; // 일일 첫 접속인지?
    attendanceStreak: number;   // 누적 출석
    dailyUnitStudy: number;     // 일일 완료 누적학습(단위학습) 수
};

const initialState: attendanceProps = {
    attendanceTimeStamp: new Date('December 21, 2023 00:00:00'), // 임의지정
    todaysFirstAccess: true,
    attendanceStreak: 0,
    dailyUnitStudy: 0,
};

/**
 * 현재 퀴즈화면인지 아닌지를 quiz변수로 나타냄
 */
export const attendance = createSlice({
    name: 'attendance',
    initialState,
    reducers: {
        // 일일 첫 출석인지 확인
        checkDailyAttendance(state){

            // 현재 시각
            const timeNow = timeFormat(new Date(), false);

            // (직전)출석 시각
            const attendanceTime = new Date(state.attendanceTimeStamp);

            // 현재 시각과 최근 출석 시각의 차(date기준)
            const timeDistance = (timeNow.getTime() - attendanceTime.getTime()) / (1000 * 3600 * 24);

            if (timeDistance < 1) {
                // 그날 출석한 기록이 이미 있을 시
                state.todaysFirstAccess = false;
                return;
            } else if (timeDistance < 2){
                // 어제 출석한 기록이 있을 때
                // 누적 출석 갱신
                state.attendanceStreak += 1;
                // 일일 단위학습 초기화
                state.dailyUnitStudy = 0;
                // 일일 출석 타임스탬프 갱신
                state.attendanceTimeStamp = new Date(timeNow.setHours(0, 0, 0, 0));
                state.todaysFirstAccess = true;
            } else {
                // 2일 이상 출석하지 않았을 때
                // 누적 출석 초기화
                state.attendanceStreak = 1;
                // 일일 단위학습 초기화
                state.dailyUnitStudy = 0;
                // 일일 출석 타임스탬프 갱신
                state.attendanceTimeStamp = new Date(timeNow.setHours(0, 0, 0, 0));
                state.todaysFirstAccess = true;
            }
        },
        // 일일 출석 reset
        resetAttendance(state){
            state.todaysFirstAccess = false;
        },
        // 일일 단위학습 수 1개 늘리기
        increaseDailyUnitStudy(state){
            state.dailyUnitStudy += 1;
        }
    },
    extraReducers: (builder) => {
        builder
            // 초기화
            .addCase(PURGE, () => initialState);
            // 출석처리 성공
            // .addCase(sendAttendance.fulfilled, (state, action) => {
            //     return true;
            // })
    },
});

export const { checkDailyAttendance, resetAttendance, increaseDailyUnitStudy } = attendance.actions;

export default attendance.reducer;