import React, { useEffect, useState } from "react";

import { Card, CardHeader, Avatar, Grid } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ReactComponent as LogoSvg } from '../../assets/logo_24px.svg';

import { Notice } from "../../model/Notice"
import notice from "../../mockData/notice.json"
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { UserType } from "model/UserModel";
import { openModal } from "redux/modules/modal";

/*
* Home화면의 알림카드
*/
const NoticeCard: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    const user:(UserType | null) = useAppSelector((state) => state.userAuth.user);

    // 로그인 상태일 때 로그인권유 Notice 숨김
    const isDisplay = (item: Notice) => { return user?.nickname && (item.noticeLink === "Login") ? "none": "block" };


    const handleNavigate = (i: null | string) => {
        // 로그인 카드를 눌렀을 때 로그인 모달 열기
        if (i === "Login") {
            dispatch(
                openModal({
                    modalType: "LoginModal",
                })
            );
        }
        // 링크가 있을 시 연결
        else if (i !== null) {
            navigate(i);
        }
    };

    const noticeList: JSX.Element[] = notice.notice.map((item: Notice, index: number) => (
        <Grid item key={index} display={isDisplay(item)}>
            <Card onClick={() => handleNavigate(item.noticeLink)}>
                <CardHeader sx={{ background: item.noticeBgColor, padding: '10px 14px', color: item.noticeColor }}
                    // startupQT 로고
                    avatar={
                        item.noticeDescription ?
                          null : <LogoSvg fill="#00639B" />
                    }
                    // 링크가 있을 시 연결되도록 함
                    action={
                            item.noticeLink ?
                                <NavigateNextIcon sx={{ margin: "auto" }} /> : null
                    }
                    // 알림 카드 내용
                    title={item.noticeTitle}
                    subheader={item.noticeDescription}
                    subheaderTypographyProps={{ style: { color: item.noticeColor } }}
                />
            </Card>
        </Grid>
    ));

    return (
        <>
            {noticeList}
        </>
    );
};

export default NoticeCard;