// 학습 목표 설정에 필요한 주제 목록

export const topics = [
    {
        "topicId": 49,
        "topicName": "창업 경영"
    },
    {
        "topicId": 50,
        "topicName": "창업 회계"
    },
    {
        "topicId": 51,
        "topicName": "기업진단론"
    },
    {
        "topicId": 48,
        "topicName": "중소기업관계법령"
    },
    {
        "topicId": 52,
        "topicName": "조사방법론"
    },
    {
        "topicId": 56,
        "topicName": "경쟁사 분석"
    },
    {
        "topicId": 57,
        "topicName": "개발 프로세스"
    },
    {
        "topicId": 60,
        "topicName": "성장과 리더십"
    },
    {
        "topicId": 61,
        "topicName": "투자 유치"
    },
    {
        "topicId": 62,
        "topicName": "운영 효율화"
    },
    {
        "topicId": 63,
        "topicName": "주간, 월간 목표 관리"
    },
    {
        "topicId": 64,
        "topicName": "문제 해결 능력"
    },
    {
        "topicId": 65,
        "topicName": "플랫폼 전략"
    },
    {
        "topicId": 67,
        "topicName": "마케팅 전략"
    }
]