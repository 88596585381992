import React from "react";

import { Typography, Grid, Snackbar } from "@mui/material";

import { QuizModel } from 'model/QuizModel';
import QuizNav from "components/QuizNav/QuizNav";
import QuizAppBar from "components/QuizAppBar/QuizAppBar";

import { motion, MotionValue } from "framer-motion";
import QuizItem from "./QuizItem";
import NoQuiz from "components/NoQuiz/NoQuiz";


interface QuizPageProps {
    currentCardRef: React.ForwardedRef<HTMLDivElement>; // 현재 문제 카드 높이를 재기 위한 ref
    quizList: QuizModel[];  // 현재, 다음 카드 리스트
    top: number;            // dragConstraints설정을 위한 top 변수
    bottom: number;         // dragConstraints설정을 위한 bottom 변수
    y: MotionValue<number>; // 콘텐츠 전체 높이 설정을 위한 y변수
    onDelete: () => void;   // 해당 문제 카드가 왼쪽으로 스와이프 되었을 때 문제를 넘김처리하는 함수
    height: number;         // 콘텐츠 전체 높이 설정을 위한 height변수
    padding: number;        // 미리 설정해둔 padding값
    loading: "idle" | "succeeded" | "failed" | "pending"; // 문제 로딩 상태

    // 스낵바
    snackbarOpen: boolean;          // 스낵바가 열려있는 상태인지?
    handleSnackbarClose: (event: React.SyntheticEvent | Event, reason?: string) => void; // 스낵바를 닫음 처리했을 시
    snackbarMessage: string;        // 스낵바 내부 메세지
};


const QuizPageView: React.FC<QuizPageProps> = (
    {
        currentCardRef,
        quizList,
        top,
        bottom,
        y,
        onDelete,
        height,
        padding,
        loading,
        snackbarOpen,
        handleSnackbarClose,
        snackbarMessage
    }) => {

    // 문제를 정상적으로 불러오지 못했을 때 퀴즈를 새로 불러올 수 있는 컴포넌트 출력
    if (loading !== "succeeded" && quizList.length < 1) {
        return (
            <div style={{ overflow: "hidden"}}>
            <QuizAppBar navType="종료하기" navKeyword="종료하기" />
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ padding: '56px 0 60px 0' }}
            >
                <div
                    style={{
                        height: height,
                        position: "relative",
                        transform: "translateZ(0)",
                    }}
                >
                    <NoQuiz/>
                </div>
            </Grid>
            <QuizNav/>
        </div>
        );
    };

    // 문제 컴포넌트 출력
    return (
        <div style={{ overflow: "hidden"}}>
            <QuizAppBar navType="종료하기" navKeyword="종료하기"/>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ padding: '56px 0 60px 0' }}
            >
                {/* 문제 또는 사용자 상태를 나타내는 Typo */}
                <Grid item
                    style={{
                        background: "linear-gradient(to bottom, rgba(244, 243, 247, 1) 80%, rgba(244, 243, 247, 0) 100%)",
                        zIndex: 6,
                        paddingLeft: "4px",
                    }}
                >
                    <Typography variant="subtitle1">🔥 {quizList[0].quizTopics[0].topicName}에 대한 문제예요.</Typography>
                </Grid>
                <div
                    style={{
                        height: height,
                        position: "relative",
                        transform: "translateZ(0)",
                    }}
                >
                    <motion.div
                        style={{ y: y, height: height }}
                        drag="y"
                        dragDirectionLock
                        dragConstraints={{ top, bottom }}
                    >
                        {/* 현재 문제, 다음 문제 */}
                        {quizList.map((value, index) => (
                            <QuizItem
                                index={index}
                                onDelete={onDelete}
                                key={value.quizId}
                                value={value}
                                currentCardRef={currentCardRef}
                                padding={padding}
                                quizLength={quizList.length}
                            />
                        ))}
                    </motion.div>
                </div>
            </Grid>
            <QuizNav/>

            {/* 인터넷 연결 스낵바 */}
            <Snackbar 
                open={snackbarOpen} 
                autoHideDuration={5000} 
                onClose={handleSnackbarClose} 
                message={snackbarMessage} 
                sx={{ bottom: { xs: 80, sm: 80 } }}
            />
        </div>
    );
};

export default QuizPageView;