import React from "react";
import { List, ListItemButton, Typography, ListItemText } from "@mui/material";
import { ExampleType, QuizModel } from "model/QuizModel";
import CustomMarkdown from 'components/CustomMarkdown/CustomMarkdown';

interface OptionProps {
    currentQuiz: QuizModel;                         // 현재 퀴즈정보
    selectedOption: number;                         // 고른 번호
    handleOptionClick: (option: number) => void;    // 선택한 지문 저장
    isQuiz: boolean;                                // 퀴즈화면이면 true, 해설화면이면 false
    disableClick: boolean;                          // 선지를 클릭했을 때 backgroundColor를 변경시킬지 (현재 문제에서 클릭한 선지가 다음 문제의 선지에 반영되지 않게 하기 위함)
}

// 각 선지에 대한 props
interface ListItemContentProps {
    index: number;      // 선지 번호
    text: string;       // 선지에 들어가는 text
}

// 선지 컴포넌트
const ListItemContent: React.FC<ListItemContentProps> = ({ index, text }) => (
    <>
        {/* 선지 번호 */}
        <Typography variant="body1" pr={2} pl={0.5} sx={{ display: 'inline' }}>
            {index + 1}
        </Typography>

        {/* 선지 Text */}
        <CustomMarkdown content={text}/>
    </>
);


const IndexTypoView: React.FC<OptionProps> = ({ currentQuiz, selectedOption, handleOptionClick, isQuiz, disableClick }) => {

    // 해설화면일 때
    const exStyle = (index: number): React.CSSProperties => {
        // [해설] 정답인 선지인지?
        const isAnswer = currentQuiz.examples[index].isAnswer;
        const isNotAnswer = selectedOption === index;

        // [해설] 선지 style
        const baseStyle: React.CSSProperties = {
            padding: "8px 16px",
        };

        // [해설] 정답 번호의 선지 배경색 변경
        if (isAnswer) {
            // 정답
            baseStyle.background = 'rgba(0, 155, 65, 0.08)';
        } else if (isNotAnswer) {
            // 오답
            baseStyle.background = 'rgba(160, 86, 104, 0.08)';
        } else {
            // 그 외
            baseStyle.background = 'transparent';
        }

        return baseStyle;
    }


    return (
        // 선지 지문 뿌리기
        <List>
            {
                isQuiz ?
                    // 문제화면일 때 선지
                    currentQuiz.examples.map((item: ExampleType, index: number) => (
                        <ListItemButton
                            key={index}
                            onClick={() => handleOptionClick(index)}
                            selected={!disableClick && selectedOption === index}
                        >
                            <ListItemContent index={index} text={item.exampleText} />
                        </ListItemButton>
                    ))
                    :
                    // 해설화면일 때 선지
                    currentQuiz.examples.map((item: ExampleType, index: number) => (
                        <ListItemText
                            key={index}
                            sx={exStyle(index)}
                        >
                            <ListItemContent index={index} text={item.exampleText} />
                        </ListItemText>
                    ))
            }
        </List>
    )
}

export default IndexTypoView;