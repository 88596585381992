import React, { useEffect } from "react";
import { useState } from "react";
import { useAnimation, Variants } from 'framer-motion';
import { useNavigate } from "react-router-dom";

import StartSliderView from "./StartSliderView";
import startupQT0 from '../../assets/StartSliderImg/startupQT0.png';
import startupQT1 from '../../assets/StartSliderImg/startupQT1.png';
import startupQT2 from '../../assets/StartSliderImg/startupQT2.png';
import startupQT3 from '../../assets/StartSliderImg/startupQT3.png';


export const images = [startupQT0, startupQT1, startupQT2, startupQT3];
export const startTypo = [
    {
        title: 'StartupQT',
        description: ['예비 창업자들의 필수 어플리케이션,', 'StartupQT에 오신 것을 환영합니다.']
    }, {
        title: '창업 지식 퀴즈',
        description: ['무엇을 어떻게 배울지 고민하지 않아도 돼요.', '틈틈이 퀴즈를 풀기만 해도 전문적인 창업 지식을 얻을 수 있어요.']
    }, {
        title: '개인 맞춤 학습',
        description: ['자체 추천 알고리즘을 통해 1:1 맞춤 학습을 경험해보세요.', '학습 분석을 통해 내 실력이 어느정도인지 확인해요.']
    }, {
        title: '상세한 해설',
        description: ['자세한 해설과 관련자료들을 통해 궁금증을 해소해요.', '']
    }
];


const StartSliderContainer = () => {
    // 현재 이미지 및 텍스트의 인덱스 상태
    const [currentIndex, setCurrentIndex] = useState(0);

    // Framer Motion의 애니메이션 제어를 위함
    const controls = useAnimation();
    const navigate = useNavigate();

    const handleClickNext = async () => {
        // Typography 사라지는 효과
        await controls.start({ opacity: 0 });

        // 다음 인덱스 계산 및 상태 업데이트
        const newIndex: number = (currentIndex + 1);
        setCurrentIndex(newIndex);

        // Typography 나타나는 효과
        await controls.start({ opacity: 1 });
    };

    const handleClickStart = () => {
        // 사이트 방문기록 갱신을 위해 localStorage에 visited 저장
        localStorage.setItem('visited', 'visited');

        // 홈으로 이동
        navigate('/home');
    }

    const imgVariants: Variants = {
        // 이미지: 오른쪽에서 들어와서 왼쪽으로 사라지는 효과
        initial: { x: 100, opacity: 0 },
        animate: { x: 0, opacity: 1 },
        exit: { x: -100, opacity: 0 },
    };

    // 전역 배경색 설정 및 방문기록 확인
    useEffect(() => {
        localStorage.getItem('visited') && navigate('/home');

        document.body.style.backgroundColor = "white";
        return () => {
            document.body.style.backgroundColor = "";
        };
    }, []);


    return (
        <StartSliderView
            controls={controls}
            currentIndex={currentIndex}
            imgVariants={imgVariants}
            handleClickStart={handleClickStart}
            handleClickNext={handleClickNext}
        />
    )
};

export default StartSliderContainer;