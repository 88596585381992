import React, { useEffect }  from "react";
import MYView from "./MYView";
import { useAppDispatch, useAppSelector } from "hooks/hooks";
import { openModal } from "redux/modules/modal";
import { useNavigate } from "react-router-dom";

/**
 * 사용자 분석 및 통계 페이지
 * @returns MY 페이지
 */
const MYContainer:React.FC = () => {
    const isLogin = useAppSelector(state => state.userAuth.userInfo);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    // 사용자의 목표 횟수와 현재까지의 학습량 가져오기
    const dailyGoal = useAppSelector(state => state.myPageData.dailyGoal);
    const quizCounter = useAppSelector(state => state.quizCounter);
    const currentUnitStudy = useAppSelector(state => state.attendance.dailyUnitStudy);
    const currentFullStudy: number = currentUnitStudy*quizCounter.maxCount + quizCounter.count;

    useEffect(() => {

        // 비로그인 상태일 때 로그인 모달 열고 home화면으로 이동
        if (isLogin === null) {
            dispatch(
                openModal({
                    modalType: "LoginModal",
                    data: "MY",
                })
            );

            navigate("/home");
        }

    },[]);

    const handleNavigate = (path: string) => {
        navigate(path);
    };
    
    return (
        <MYView 
            count = {currentFullStudy}
            dailyGoal={dailyGoal}
            handleNavigate={handleNavigate}
        />
    );
};

export default MYContainer;